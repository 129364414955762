import { withPageTracking } from 'common/lib/analytics';
import { APP_PATHS } from 'common/lib/data';
import { withPageTransition } from 'components/PageTransition';
import ErrorPage from 'pages/ErrorPage';
import IndexPage from 'pages/IndexPage';
import InstructionsPage from 'pages/InstructionsPage';
import InviteErrorPage from 'pages/InviteErrorPage';
import LogoutPage from 'pages/LogoutPage';
import NotFoundPage from 'pages/NotFoundPage';
import PrintResultsPage from 'pages/PrintResultsPage';
import ResultsPage from 'pages/ResultsPage';
import StartPage from 'pages/StartPage';
import SurveyPage from 'pages/SurveyPage';

const routes = [
  {
    path: APP_PATHS.logout,
    component: withPageTracking(withPageTransition(LogoutPage)),
  },
  {
    path: APP_PATHS.error,
    component: withPageTracking(withPageTransition(ErrorPage)),
  },
  {
    path: APP_PATHS.inviteError,
    component: withPageTracking(withPageTransition(InviteErrorPage)),
  },
  {
    path: APP_PATHS.start,
    component: withPageTracking(withPageTransition(StartPage)),
  },
  {
    path: APP_PATHS.instructions,
    component: withPageTracking(withPageTransition(InstructionsPage)),
  },
  {
    path: APP_PATHS.results,
    component: withPageTracking(withPageTransition(ResultsPage)),
  },
  {
    path: APP_PATHS.printResults,
    component: withPageTracking(PrintResultsPage),
  },
  {
    path: APP_PATHS.survey,
    component: withPageTracking(withPageTransition(SurveyPage)),
  },
  {
    path: APP_PATHS.notFound,
    component: withPageTracking(withPageTransition(NotFoundPage)),
  },
  {
    path: '',
    component: withPageTracking(withPageTransition(IndexPage)),
  },
  {
    path: '*',
    component: withPageTracking(withPageTransition(NotFoundPage)),
  },
];

export default routes;
