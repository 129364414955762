import { Notifier } from '@airbrake/browser';
import { __ENV__, __PRODUCTION__ } from './data';
import { error } from './log';

const projectId = process.env.REACT_APP__AIRBRAKE_PROJECT_ID;
const projectKey = process.env.REACT_APP__AIRBRAKE_PROJECT_KEY;

const airbrake =
  projectId &&
  projectKey &&
  new Notifier({
    projectId,
    projectKey,
    environment: __ENV__,
  });

export default __PRODUCTION__
  ? airbrake
  : {
      notify: (event) => error('[ERROR]', event.message, event),
    };
