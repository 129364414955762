/**
 * Random Integer
 * @param {Integer} min 1
 * @param {Integer} max 100
 * @returns {Integer}
 */
export const randomInt = (min = 1, max = Number.MAX_SAFE_INTEGER) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Random HSL Color
 * Note: Values are bracketed to avoid ugly colors
 * @returns {String} "hsl(360, 45, 45)"
 */
export const randomColor = () =>
  `hsl(${randomInt(0, 360)},${randomInt(42, 98)}%,${randomInt(40, 90)}%)`;

/**
 * @returns {String} "0f1742njbehb5jlngzsgr0s"
 */
export const randomHash = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);
