import { APP_CACHE_KEY, APP_FORM_CACHE_KEY } from './data';
import features from './features';
import persist from './persist';

export const persistState = (nextState) => {
  if (features.autosave()) {
    // log('[StateHelpers] Persist State');
    // Remove properties from state we don't want to persist
    // e.g. scheme, let the page figure out scheme at render time
    const { scheme, isChangingScheme, ...cleanState } = nextState;
    // Keep a copy of application state in localStorage
    persist.local.write(APP_CACHE_KEY, cleanState);
  }
};

export const clearPersistedState = () => {
  if (features.autosave()) {
    // log('[StateHelpers] Clear Persisted State');
    persist.local.clear(APP_FORM_CACHE_KEY);
    persist.local.clear(APP_CACHE_KEY);
  }
};
