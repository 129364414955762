import { APP_FORM_CACHE_KEY, DEBOUNCE_RATE } from 'common/lib/data';
import persist from 'common/lib/persist';
import rescue from 'common/lib/rescue';
import { connect } from 'formik';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import React from 'react';

const saveForm = rescue(
  debounce(({ formik }) => {
    // Remove some fields from localStorage copy
    //  - `isSubmitting` prevents submission button being disabled
    //  - `errors`, `touched`, and `submitCount` prevents error messages from appearing upon returning
    const {
      isSubmitting,
      errors,
      touched,
      submitCount,
      ...formikData
    } = formik; // eslint-disable-line no-unused-vars
    persist.local.write(APP_FORM_CACHE_KEY, formikData);
  }, DEBOUNCE_RATE)
);

const applyForm = rescue((formik) => {
  const state = persist.local.read(APP_FORM_CACHE_KEY);
  if (state && state !== null) {
    formik.setFormikState(state);
  }
});

const Autosave = ({ formik, enabled = false }) => {
  const prevFormik = React.useRef(formik).current;

  React.useEffect(() => {
    enabled && applyForm(formik);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!isEqual(prevFormik, formik)) {
      enabled && saveForm({ formik });
    }
  }, [formik]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

Autosave.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default connect(Autosave);
