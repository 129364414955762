import React from 'react';
const defaultRadarAxisStyle = {
  axisOverreach: 1.1,
  labelOverreach: 1.35,
  fontSize: 10,
  fontFamily: 'sans-serif',
  textFill: 'black',
  axisWidth: 1,
  yOffsetFactor: 15,
  maxCharCount: 15,
  axisCircleFill: '#D8E9FE',
  labelOffset: {
    // agreeableness
    0: 0,
    // conscientiousness
    1: 40,
    // extraversion
    2: 0,
    // neuroticism
    3: 0,
    // opennessToExperience
    4: 60,
  },
};

export default function RadarAxis(props) {
  const {
    scale,
    offsetAngle,
    domainMax,
    keyIndex,
    label,
    color,
    style,
  } = props;
  const {
    axisOverreach,
    labelOverreach,
    fontSize,
    fontFamily,
    textFill,
    axisWidth,
    yOffsetFactor,
    maxCharCount,
    axisCircleFill,
    labelOffset,
  } = { ...defaultRadarAxisStyle, style };
  const xFactor = Math.cos(offsetAngle - Math.PI / 2);
  const yFactor = Math.sin(offsetAngle - Math.PI / 2);
  const splitLabel = label.split(' ');
  const getLabel = (label, index) => {
    return (
      <text
        x={scale(domainMax * labelOverreach) * xFactor}
        y={
          scale(domainMax * labelOverreach) * yFactor +
          index * yOffsetFactor -
          labelOffset[keyIndex]
        }
        fontSize={fontSize}
        fontFamily={fontFamily}
        fill={textFill}
        textAnchor={'middle'}
        dy={'0.35em'}
      >
        {label}
      </text>
    );
  };

  return (
    <g>
      <line
        x1={0}
        y1={0}
        x2={scale(domainMax) * xFactor}
        y2={scale(domainMax) * yFactor}
        stroke={color}
        strokeWidth={axisWidth}
      />
      {label.length <= maxCharCount
        ? getLabel(label, 0)
        : splitLabel.map((label, index) => getLabel(label, index))}
      <circle
        key={scale(domainMax * axisOverreach)}
        r={3.3}
        cx={scale(domainMax * axisOverreach) * xFactor}
        cy={scale(domainMax * axisOverreach) * yFactor}
        opacity={1}
        fill={axisCircleFill}
      />
    </g>
  );
}
