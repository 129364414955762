/**
 * @link https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/chunk.md
 * @param {Array} arr
 * @param {Integer} size
 * @returns {Array}
 */
export const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

/**
 * Shuffle (Randomize) Array
 * @param {Array} array
 * @returns {Array}
 */
export const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
