import ACTIONS from 'common/lib/actions';
import { APP_PATHS, SCHEME_DARK } from 'common/lib/data';
import { useStateValue } from 'common/lib/state';
import { clearPersistedState } from 'common/lib/stateHelpers';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyLogout from 'components/SurveyLogout';
import React from 'react';

const LogoutPage = () => {
  useLayoutScheme(SCHEME_DARK);
  const [{ founder }, dispatch] = useStateValue();
  const { code } = founder;

  React.useEffect(() => {
    dispatch({
      type: ACTIONS.RESET_SURVEY,
    });

    !code &&
      setTimeout(() => {
        clearPersistedState();
        document.location.pathname = APP_PATHS.root;
      }, 1000);
  }, [code, dispatch]);

  return (
    <>
      <SEO title="Get Started" />
      <SurveyLogout />
    </>
  );
};

export default LogoutPage;
