import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './styles.module.scss';

export default function Iframe({
  src,
  className,
  containerClassName,
  title = 'iframe',
  onLoad = () => null,
  ...attrs
}) {
  let [visible, setVisible] = useState(false);

  return (
    <div
      className={cx(styles.IframeContainer, containerClassName, {
        [styles.visible]: visible,
      })}
    >
      {
        <iframe
          className={cx(styles.Iframe, className)}
          title={title}
          src={src}
          onLoad={(event) => {
            setVisible(true);
            onLoad(event);
          }}
          {...attrs}
        ></iframe>
      }
    </div>
  );
}

Iframe.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onLoad: PropTypes.func,
  src: PropTypes.string.isRequired,
};
