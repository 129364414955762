import ACTIONS from 'common/lib/actions';
import api from 'common/lib/api';
import { useStateValue } from 'common/lib/state';
import { MaterialFormField } from 'components/FormField';
import Loader from 'components/Loader';
import Art from 'components/SurveyIntermission/Art';
import { useFormik } from 'formik';
import React from 'react';
import usePortal from 'react-useportal';
import Modal from '../Modal';
import styles from './styles.module.scss';

const Form = ({ onSuccess }) => {
  const [{ founder }, dispatch] = useStateValue();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues: {
      first: '',
      last: '',
      email: '',
      organization: '',
    },
    onSubmit: (values, { resetForm }) => {
      if (!isSubmitting) {
        setIsSubmitting(true);
        api
          .postReferral({ ...values }, founder.token)
          .then((response) => {
            dispatch({
              type: ACTIONS.HANDLE_REFERRAL_SUCCESS,
              response: response.parsed,
            });
            onSuccess();
            resetForm();
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <MaterialFormField
        className={styles.referralField}
        label="Founder Email"
        id="email"
        type="email"
        name="email"
        required
        onChange={formik.handleChange}
        value={formik.values.email}
        variant="outlined"
      />
      <MaterialFormField
        className={styles.referralField}
        label="First Name"
        id="first"
        type="text"
        name="first"
        required
        onChange={formik.handleChange}
        value={formik.values.first}
        variant="outlined"
      />
      <MaterialFormField
        className={styles.referralField}
        label="Last Name"
        id="last"
        type="text"
        name="last"
        required
        onChange={formik.handleChange}
        value={formik.values.last}
        variant="outlined"
      />
      <MaterialFormField
        className={styles.referralField}
        label="Organization"
        id="organization"
        type="text"
        name="organization"
        required
        onChange={formik.handleChange}
        value={formik.values.organization}
        variant="outlined"
      />
      {isSubmitting ? (
        <Loader className={styles.submitting} />
      ) : (
        <MaterialFormField
          id="submit"
          type="submit"
          name="submit"
          value="Submit"
          variant="outlined"
          disabled={isSubmitting}
        />
      )}
    </form>
  );
};

const Referral = ({ show, onClose, onSuccess }) => {
  const { Portal } = usePortal();

  return (
    <Portal>
      <Modal className={styles.referralModal} show={show} onClose={onClose}>
        <div className={styles.referralContainer}>
          <Art className={styles.referralArt} />
          <h2>Refer a founder</h2>
          <p>
            Invite a co-founder, colleague, or friend to take the Human Founder
            assessment & compare your results!
          </p>
          <Form
            onSuccess={() => {
              onClose();
              onSuccess();
            }}
          />
        </div>
      </Modal>
    </Portal>
  );
};

export default Referral;
