import cx from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { KEYCODE_ESC } from '../../common/lib/data';
import styles from './styles.module.scss';

export default function Glass({
  onClick,
  isActive,
  theme = 'light',
  title = 'Close',
}) {
  // Open / Close Effects
  useEffect(() => {
    // ESC Event Handler
    const onEscape = (event) => {
      event.keyCode === KEYCODE_ESC && isActive && onClick();
    };
    // Open Render
    if (isActive === true) {
      // Listen for ESC
      document.addEventListener('keydown', onEscape);
    }
    // Close Render
    return function cleanUp() {
      // Stop Listen for ESC
      document.removeEventListener('keydown', onEscape);
    };
  }, [isActive, onClick]);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      className={cx(
        {
          [styles.show]: isActive,
          [styles.hide]: !isActive,
        },
        styles.Glass,
        styles[theme],
        'glass'
      )}
      onClick={onClick}
      title={isActive ? title : null}
    />
  );
}

Glass.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};
