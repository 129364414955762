const isEnabled = (key) => process.env[`REACT_APP__FEATURE__${key}`] === 'true';

const features = {
  autosave: () => isEnabled('AUTOSAVE'),
};

features._all = Object.keys(features).reduce((list, feature) => {
  list[feature] = features[feature]();
  return list;
}, {});

export default features;
