import { client } from './client';
import { APP_FOUNDER_API_HOST, __DEVELOPMENT__ } from './data';

const url = (path) => `${APP_FOUNDER_API_HOST}${path}`;

let headers = (token) => ({
  Authorization: `Bearer ${token}`,
});

const api = {
  getSurvey: (token) =>
    client.get(url('/big_five_surveys'), { headers: headers(token) }),
  postReferral: (body, token) =>
    client.post(url('/referrals'), {
      body,
      headers: headers(token),
    }),
  postSurvey: (body, token) =>
    client.post(url('/big_five_surveys/submit'), {
      body,
      headers: headers(token),
    }),
};

export default api;

/**
 * Expose API functions to window for
 * convenient development & debugging
 */
if (__DEVELOPMENT__) {
  window.FounderAPI = api;
}
