import cx from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

const Progress = ({ max, value, className, label, animate = false }) => {
  const [animated, setAnimated] = React.useState(!animate);
  const [currentValue, setCurrentValue] = React.useState(0);

  React.useEffect(() => {
    if (animate && !animated) {
      setAnimated(true);
      setCurrentValue(value);
    }
  }, [animate, animated, value]);

  return (
    <progress
      max={max}
      value={!animate ? value : currentValue}
      className={cx(styles.Progress, className)}
      data-label={label}
    >
      <div className={styles.ProgressFallback}>{label}</div>
    </progress>
  );
};

export default Progress;
