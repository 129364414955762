import { APP_PATHS } from 'common/lib/data';
import { useStateValue } from 'common/lib/state';
import { redeem } from 'common/lib/surveyHelpers';
import ArrowButton from 'components/ArrowButton';
import Card from 'components/Card';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import Logo from 'components/Logo';
import { useField } from 'formik';
import React from 'react';
import { Redirect } from 'react-router-dom';
import styles from './styles.module.scss';

const REFERRAL_COPY = 'Enter your personal referral code';
const CODE_LENGTH = 14;
const CODE_PATTERN = '^founder-(......)$';
const CODE_REGEXP = new RegExp(CODE_PATTERN);

export default function SurveyRedeem() {
  const [loading, setLoading] = React.useState(null);
  const [field, meta, helpers] = useField('code'); // eslint-disable-line no-unused-vars
  const [{ isSequenceReady, inviteError }, dispatch] = useStateValue(); // eslint-disable-line no-empty-pattern

  const redeemCode = React.useCallback(
    (code) => {
      setLoading(true);
      redeem({
        code,
        dispatch,
        callback: () => {
          setLoading(false);
        },
      });
    },
    [dispatch]
  );

  React.useEffect(() => {
    const params = new URLSearchParams(
      document.location.search.split('?').pop()
    );
    const code = params.get('q');

    if (
      code &&
      !meta.value &&
      code.length === CODE_LENGTH &&
      CODE_REGEXP.test(code)
    ) {
      helpers.setValue(code);
      // Wait a few moments to redeem code so the page can finish rendering!
      setTimeout(() => redeemCode(code), 500);
    }
  }, [helpers, meta.value, redeemCode]);

  if (isSequenceReady === true) {
    return <Redirect to={APP_PATHS.start} />;
  }
  if (inviteError) {
    return <Redirect to={APP_PATHS.inviteError} />;
  }

  return (
    <Card className={styles.RedeemCard}>
      <Logo className={styles.RedeemLogo} />
      <div className={styles.RedeemText}>
        Please reach out to <a href="mailto:human@human.vc">human@human.vc</a>{' '}
        to request your code.
      </div>
      <FormField
        className={styles.RedeemInput}
        id="code"
        label={REFERRAL_COPY}
        placeholder={REFERRAL_COPY}
        required
        type="password"
        minLength={CODE_LENGTH}
        maxLength={CODE_LENGTH}
        pattern={CODE_PATTERN}
        onChange={(event) => {
          helpers.setValue(event.target.value);
        }}
        onKeyDown={(keyEvent) => {
          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
            redeemCode(meta.value);
          }
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <ArrowButton onClick={() => redeemCode(meta.value)} />
        )}
      </FormField>
    </Card>
  );
}
