import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import cx from 'classnames';
import { trackEvent } from 'common/lib/analytics';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styles from './styles.module.scss';

const HelpText = () => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{
          opacity: 0,
          x: '25%',
        }}
        animate={{
          opacity: 1,
          x: '0%',
        }}
        exit={{
          opacity: 0,
          x: '25%',
        }}
      >
        Need help or have questions? Contact us at&nbsp;
        <a
          href="mailto:product@human.vc?subject=Founder Survey Help"
          onClick={() => {
            trackEvent(trackEvent.EVENT__CLICK_HELP);
          }}
        >
          product@human.vc
        </a>
      </motion.div>
    </AnimatePresence>
  );
};

export default function Help() {
  const [showHelp, setShowHelp] = React.useState(false);

  return (
    <motion.div className={cx(styles.help, { [styles.active]: showHelp })}>
      {showHelp && <HelpText />}
      <HelpOutlineIcon onClick={() => setShowHelp(!showHelp)} />
    </motion.div>
  );
}
