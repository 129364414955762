import ACTIONS from 'common/lib/actions';
import api from 'common/lib/api';
import { APP_FORM_NAME, APP_PATHS, INITIAL_VALUES } from 'common/lib/data';
import features from 'common/lib/features';
import { useStateValue } from 'common/lib/state';
import Controls from 'components/Controls';
import FormikWithRef from 'components/FormikWithRef';
import { Form as FormikForm } from 'formik';
import React from 'react';
import { Route } from 'react-router-dom';
import Autosave from './Autosave';

export default React.forwardRef((props, ref) => {
  const [{ founder }, dispatch] = useStateValue();
  const [submitSuccess, setSubmitSuccess] = React.useState();

  // info('[Formik]', 'InitialValue', initalValue);

  return (
    <FormikWithRef
      ref={ref}
      enableReinitialize // Reflows form when it changes
      initialValues={INITIAL_VALUES}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        !submitSuccess &&
          api
            .postSurvey(
              {
                // Note: form-name is left over from submitting to Netlify
                'form-name': APP_FORM_NAME,
                ...values,
              },
              founder.token
            )
            .then((response) => {
              // info('[Formik]', 'Submission Success!');
              setSubmitting(false);
              setSubmitSuccess(true);
              dispatch({
                type: ACTIONS.HANDLE_SUBMIT_SUCCESS,
                response: response.parsed,
              });
            })
            .catch((error) => {
              // info('[Formik]', 'Submission Error: Please Try Again!');
              setSubmitting(false);
              setSubmitSuccess(false);
            });
      }}
    >
      <FormikForm netlify-honeypot="bot-field" data-netlify="true">
        <input type="hidden" name="form-name" value={APP_FORM_NAME} />
        {props.children}
        <Autosave enabled={features.autosave()} />
        <Route path={APP_PATHS.survey} component={Controls} />
      </FormikForm>
    </FormikWithRef>
  );
});
