import cx from 'classnames';
import { useStateValue } from 'common/lib/state';
import React from 'react';
import styles from './styles.module.scss';

const DURATION = 3;

// TODO: Replace this with a Laoder
const Loader = ({ className }) => {
  const [{ scheme }] = useStateValue();

  return (
    <div className={cx(styles.LoaderLink, styles[scheme], className)}>
      <svg
        className={styles.LoaderSvg}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 66 67"
        preserveAspectRatio="xMidYMid"
      >
        <path
          className={styles.LoaderSvgFill}
          fill="#fff"
          d="M0 32.942C0 14.748 14.748 0 32.942 0c18.193 0 32.941 14.748 32.941 32.942v1.116C65.883 52.252 51.135 67 32.942 67 14.748 67 0 52.252 0 34.058v-1.116z"
        />
        <circle
          className={styles.LoaderSvgStroke}
          cx="33"
          cy="33.5"
          r="0"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur={`${DURATION}s`}
            values="0;30"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
          ></animate>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur={`${DURATION}s`}
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
          ></animate>
        </circle>
        <circle
          className={styles.LoaderSvgStroke}
          cx="33"
          cy="33.5"
          r="15"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur={`${DURATION}s`}
            values="0;30"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin={`-${DURATION / 2}s`}
          ></animate>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur={`${DURATION}s`}
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin={`-${DURATION / 2}s`}
          ></animate>
        </circle>
      </svg>
    </div>
  );
};

export default Loader;
