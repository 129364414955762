import { error } from './log';

/**
 * Reducer Actions
 */

export const CLEAR_INVITE_ERROR = 'CLEAR_INVITE_ERROR';
export const HANDLE_INVITE_ERROR = 'HANDLE_INVITE_ERROR';
export const HANDLE_INVITE_SUCCESS = 'HANDLE_INVITE_SUCCESS';
export const PERSIST_FORM = 'PERSIST_FORM';
export const SET_COLOR_SCHEME = 'SET_COLOR_SCHEME';
export const RESET_SURVEY = 'RESET_SURVEY';
export const HANDLE_SUBMIT_SUCCESS = 'HANDLE_SUBMIT_SUCCESS';
export const HANDLE_REFERRAL_SUCCESS = 'HANDLE_REFERRAL_SUCCESS';
export const HANDLE_GET_SURVEY = 'HANDLE_GET_SURVEY';
export const UPDATE_RESULTS_PAGE = 'UPDATE_RESULTS_PAGE';
export const UPDATE_LAST_STEP = 'UPDATE_LAST_STEP';

const ACTIONS = {
  CLEAR_INVITE_ERROR,
  HANDLE_GET_SURVEY,
  HANDLE_INVITE_ERROR,
  HANDLE_INVITE_SUCCESS,
  HANDLE_REFERRAL_SUCCESS,
  HANDLE_SUBMIT_SUCCESS,
  PERSIST_FORM,
  RESET_SURVEY,
  SET_COLOR_SCHEME,
  UPDATE_RESULTS_PAGE,
  UPDATE_LAST_STEP,
};

export const validateActionType = (name) => {
  if (!ACTIONS[name]) {
    error('[Undefined Action]', name);
  }
  return true;
};

export default ACTIONS;
