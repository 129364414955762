import cx from 'classnames';
import { useStateValue } from 'common/lib/state';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const ICON_PATH = {
  arrow:
    'M21.334 33.938h22.673M45.376 33.77l-8.16-8.043M45.376 33.836l-8.16 8.043',
  close:
    'M33.223 33.836l8.16 8.043M33.223 33.77l8.16-8.043M33.375 33.77l-8.16-8.043M33.375 33.836l-8.16 8.043',
};

const components = {
  Link,
  Button: ({ children, ...props }) => <div {...props}>{children}</div>,
};

const ArrowButton = ({
  className,
  to = null,
  onClick = null,
  icon = 'arrow',
}) => {
  const [{ scheme }] = useStateValue();

  const Component = to !== null ? components.Link : components.Button;

  return (
    <Component
      className={cx(styles.ArrowLink, styles[scheme], className)}
      to={to}
      onClick={onClick}
    >
      <svg
        className={cx(styles.ArrowSvg)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 66 67"
      >
        <path
          className={styles.ArrowSvgFill}
          fill="#fff"
          d="M0 32.942C0 14.748 14.748 0 32.942 0c18.193 0 32.941 14.748 32.941 32.942v1.116C65.883 52.252 51.135 67 32.942 67 14.748 67 0 52.252 0 34.058v-1.116z"
        />
        <path
          className={styles.ArrowSvgStroke}
          stroke="#000"
          strokeLinecap="square"
          strokeWidth="1.675"
          d={ICON_PATH[icon] || ICON_PATH.arrow}
        />
      </svg>
    </Component>
  );
};

export default ArrowButton;
