import { Collapse } from '@material-ui/core';
import cx from 'classnames';
import { getPreview } from 'common/lib/strings';
import Progress from 'components/Progress';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './styles.module.scss';

const DetailsCard = ({
  isMainTrait,
  trait,
  printable,
  onHideDetails,
  ...evaluation
}) => {
  const percentage = evaluation.percentage;
  const [open, setOpen] = React.useState(printable);
  const buttonLabel = open ? 'Collapse' : 'Read More';
  const toggleCollapsible = () => setOpen(!open);
  const previewParagraph = getPreview(evaluation.overview, 75).concat('...');

  return (
    <div className={cx(styles.details, styles[trait])}>
      <div className={styles.outcomeTitle}>
        <div
          className={
            isMainTrait ? styles.detailsTraitTitle : styles.detailsSubTraitTitle
          }
        >
          {evaluation.label}
        </div>
        <span
          className={
            isMainTrait
              ? styles.outcomeTraitNumber
              : styles.outcomeSubTraitNumber
          }
        >
          {percentage}
        </span>
      </div>
      <Progress
        max={100}
        value={percentage}
        ariaLabel={`${percentage} / 100`}
        animate={true}
        className={styles.Progress}
      />

      {!open && (
        <div>
          <ReactMarkdown source={previewParagraph} />
        </div>
      )}

      <Collapse in={open} timeout="auto" unmountOnExit>
        <div>
          <ReactMarkdown source={evaluation.overview} />
        </div>
        <div>
          <ReactMarkdown source={evaluation.summary} />
        </div>
      </Collapse>

      {!printable && (
        <button className={styles.collapseButton} onClick={toggleCollapsible}>
          {buttonLabel}
        </button>
      )}
    </div>
  );
};

export default DetailsCard;
