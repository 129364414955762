import { motion } from 'framer-motion';
import React from 'react';
import styles from './styles.module.scss';

const VARIANTS = {
  PUSH: {
    initial: {
      x: '100%',
      y: 0,
      pointerEvents: 'none',
    },
    in: {
      x: 0,
      y: 0,
      pointerEvents: 'initial',
    },
    out: {
      x: '-100%',
      y: 0,
      pointerEvents: 'none',
    },
  },
  POP: {
    initial: {
      x: '-100%',
      y: 0,
      pointerEvents: 'none',
    },
    in: {
      x: 0,
      y: 0,
      pointerEvents: 'initial',
    },
    out: {
      x: '100%',
      y: 0,
      pointerEvents: 'none',
    },
  },
};

const transition = {
  duration: 0.35,
  ease: 'easeInOut',
};

export default function PageTransition({ children }) {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={VARIANTS.PUSH}
      transition={transition}
      className={styles.PageTransition}
    >
      {children}
    </motion.div>
  );
}

/**
 * @param {Component} Component
 */
export const withPageTransition = (Component) => {
  function HOC(props) {
    return (
      <PageTransition>
        <Component {...props} />
      </PageTransition>
    );
  }
  return HOC;
};
