import cx from 'classnames';
import { shuffle } from 'common/lib/array';
import { randomInt } from 'common/lib/random';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styles from './styles.module.scss';

const VARIANTS = {
  simple: {
    initial: {
      scale: 1,
      rotate: [0, -25, 25][randomInt(1, 3) - 1],
    },
    in: {
      scale: shuffle([1.5, 2, 3]),
      rotate: shuffle([0, -10, 10, 25, -25, 45, -45, -60, 15, 60, -15, -5, 5]),
    },
    out: {
      scale: 1,
      rotate: 0,
    },
  },
};

const TRANSITION = {
  duration: 500,
};

const Art = ({ className, isVisible = true, animate }) => (
  <AnimatePresence>
    <div className={cx(styles.art, className)}>
      {isVisible && (
        <motion.svg
          initial="initial"
          animate="in"
          exit="out"
          variants={VARIANTS.simple}
          transition={TRANSITION}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 4486 2441"
          style={{ originX: '50%', originY: '50%' }}
        >
          <path
            stroke="#EEE"
            strokeWidth="3"
            d="M1287 440l-29 92M2444 349.28l92 29M2012 202.5L1463.3 66M2094 230.28l114 29M1308 682.961l56.38 16.23M2686.96 328.28l16.23-56.384M1400.18 764l5.82 101M2768 236.097l101-5.817M1400.53 947L1329 1175M2951 235.752l228 71.527M724 1439l453-100M3443 912.28l-100-453.001M2425.18 1917.16l342.31-313.1M635 1339l112-218M4179.05 2172l112-218M3343 1001.28l-218-112M2298.11 1875.06l-12.01-244.79M802 898l-17.5-184.5M4346.05 1731l-17.5-184.5M2902 834.28l-184.5 17.5M2222.23 1409.64l-107.4-151.03M575 802l142.516-114M4119.05 1635l142.52-114M2806 1061.28l-114-142.516M1977.64 1440l66.43-169.98M182 1270l-42-187M3726.05 2103l-42-187M4063.5 1684.5l-249 464.5M3274 1454.28l-187 42M3587 1594l-194-141.53M1871.3 2041.8l-129.88-140.94M117 1004L77.473 842M3661.05 1837l-39.53-162M3008 1519.28l-162 39.53M1682 1843.94l-115.23-120.53"
          />
          <circle
            cx="1394.8"
            cy="723.62"
            r="41.006"
            fill="#F5B840"
            fillOpacity=".53"
            transform="rotate(20.712 1394.8 723.62)"
          />
          <circle
            cx="2727.62"
            cy="241.477"
            r="41.006"
            fill="#F5B840"
            fillOpacity=".53"
            transform="rotate(-69.287 2727.62 241.477)"
          />
          <circle
            cx="1405.89"
            cy="908.453"
            r="42"
            fill="#F5B840"
            transform="rotate(20.712 1405.89 908.453)"
          />
          <circle
            cx="2912.45"
            cy="230.393"
            r="42"
            fill="#F5B840"
            transform="rotate(-69.287 2912.45 230.393)"
          />
          <circle
            cx="1287.04"
            cy="1285.04"
            r="119.5"
            fill="#B42E2E"
            transform="rotate(20.712 1287.04 1285.04)"
          />
          <circle
            cx="3289.04"
            cy="349.239"
            r="119.5"
            fill="#B42E2E"
            transform="rotate(-69.287 3289.04 349.239)"
          />
          <circle
            cx="1308.32"
            cy="315.319"
            r="129.025"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(20.712 1308.32 315.319)"
          />
          <circle
            cx="2319.32"
            cy="327.961"
            r="129.025"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(-69.287 2319.32 327.961)"
          />
          <circle
            cx="1246.57"
            cy="619.569"
            r="88.879"
            fill="#F5B840"
            fillOpacity=".42"
            transform="rotate(20.712 1246.57 619.569)"
          />
          <circle
            cx="2623.57"
            cy="389.711"
            r="88.879"
            fill="#F5B840"
            fillOpacity=".42"
            transform="rotate(-69.287 2623.57 389.711)"
          />
          <circle
            cx="1421.14"
            cy="54.14"
            r="42"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(20.712 1421.14 54.14)"
          />
          <circle
            cx="2058.14"
            cy="215.14"
            r="42"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(-69.287 2058.14 215.14)"
          />
          <circle
            cx="603.041"
            cy="1453.04"
            r="119.5"
            fill="#978AE3"
            transform="rotate(20.712 603.041 1453.04)"
          />
          <circle
            cx="4147.09"
            cy="2286.04"
            r="119.5"
            fill="#978AE3"
            transform="rotate(20.712 4147.09 2286.04)"
          />
          <circle
            cx="3457.04"
            cy="1033.24"
            r="119.5"
            fill="#978AE3"
            transform="rotate(-69.287 3457.04 1033.24)"
          />
          <circle
            cx="2327.45"
            cy="1989.8"
            r="119.5"
            fill="#978AE3"
            transform="rotate(-9.288 2327.45 1989.8)"
          />
          <circle
            cx="541.12"
            cy="820.527"
            r="37.729"
            fill="#408F9C"
            transform="rotate(20.712 541.12 820.527)"
          />
          <circle
            cx="4085.17"
            cy="1653.53"
            r="37.729"
            fill="#408F9C"
            transform="rotate(20.712 4085.17 1653.53)"
          />
          <circle
            cx="2824.53"
            cy="1095.16"
            r="37.729"
            fill="#408F9C"
            transform="rotate(-69.287 2824.53 1095.16)"
          />
          <circle
            cx="1957.57"
            cy="1472.99"
            r="37.729"
            fill="#408F9C"
            transform="rotate(-9.288 1957.57 1472.99)"
          />
          <circle
            cx="791.642"
            cy="1014.64"
            r="116.088"
            fill="#408F9C"
            fillOpacity=".6"
            transform="rotate(20.712 791.642 1014.64)"
          />
          <circle
            cx="4335.69"
            cy="1847.64"
            r="116.088"
            fill="#408F9C"
            fillOpacity=".6"
            transform="rotate(20.712 4335.69 1847.64)"
          />
          <circle
            cx="3018.64"
            cy="844.638"
            r="116.088"
            fill="#408F9C"
            fillOpacity=".6"
            transform="rotate(-69.287 3018.64 844.638)"
          />
          <circle
            cx="2271.58"
            cy="1515.84"
            r="116.088"
            fill="#408F9C"
            fillOpacity=".6"
            transform="rotate(-9.288 2271.58 1515.84)"
          />
          <circle
            cx="769.719"
            cy="642.719"
            r="71.153"
            fill="#408F9C"
            transform="rotate(20.712 769.719 642.719)"
          />
          <circle
            cx="4313.77"
            cy="1475.72"
            r="71.153"
            fill="#408F9C"
            transform="rotate(20.712 4313.77 1475.72)"
          />
          <circle
            cx="2646.72"
            cy="866.56"
            r="71.153"
            fill="#408F9C"
            transform="rotate(-69.287 2646.72 866.56)"
          />
          <circle
            cx="2066.64"
            cy="1204.7"
            r="71.153"
            fill="#408F9C"
            transform="rotate(-9.288 2066.64 1204.7)"
          />
          <circle
            cx="61.348"
            cy="801.99"
            r="46.856"
            fill="#978AE3"
            transform="rotate(20.712 61.348 801.99)"
          />
          <circle
            cx="3605.4"
            cy="1634.99"
            r="46.856"
            fill="#978AE3"
            transform="rotate(20.712 3605.4 1634.99)"
          />
          <circle
            cx="2805.99"
            cy="1574.93"
            r="46.856"
            fill="#978AE3"
            transform="rotate(-69.287 2805.99 1574.93)"
          />
          <circle
            cx="1532.8"
            cy="1696.82"
            r="46.856"
            fill="#978AE3"
            transform="rotate(-9.288 1532.8 1696.82)"
          />
          <circle
            cx="131.14"
            cy="1042.14"
            r="42"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(20.712 131.14 1042.14)"
          />
          <circle
            cx="3675.19"
            cy="1875.14"
            r="42"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(20.712 3675.19 1875.14)"
          />
          <circle
            cx="3046.14"
            cy="1505.14"
            r="42"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(-69.287 3046.14 1505.14)"
          />
          <circle
            cx="1713.32"
            cy="1869.9"
            r="42"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(-9.288 1713.32 1869.9)"
          />
          <circle
            cx="205.9"
            cy="1329.9"
            r="65.087"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(20.712 205.9 1329.9)"
          />
          <circle
            cx="3749.95"
            cy="2162.9"
            r="65.087"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(20.712 3749.95 2162.9)"
          />
          <circle
            cx="3333.9"
            cy="1430.38"
            r="65.087"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(-69.287 3333.9 1430.38)"
          />
          <circle
            cx="1921.94"
            cy="2081.73"
            r="65.087"
            fill="#419BF9"
            fillOpacity=".56"
            transform="rotate(-9.288 1921.94 2081.73)"
          />
        </motion.svg>
      )}
    </div>
  </AnimatePresence>
);

export default Art;
