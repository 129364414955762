import ACTIONS from 'common/lib/actions';
import { APP_PATHS, SCHEME_ERROR } from 'common/lib/data';
import { useStateValue } from 'common/lib/state';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyInvalid from 'components/SurveyInvalid';
import React from 'react';
import { Redirect } from 'react-router-dom';

const InviteErrorPage = () => {
  useLayoutScheme(SCHEME_ERROR);

  const [{ inviteError }, dispatch] = useStateValue();
  const clearError = () =>
    dispatch({
      type: ACTIONS.CLEAR_INVITE_ERROR,
    });

  if (!inviteError) {
    return <Redirect to={APP_PATHS.root} />;
  }

  return (
    <>
      <SEO title="Something went wrong" />
      <SurveyInvalid {...inviteError} clearError={clearError} />
    </>
  );
};

export default InviteErrorPage;
