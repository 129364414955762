import { APP_PATHS } from 'common/lib/data';
import ArrowButton from 'components/ArrowButton';
import Card from 'components/Card';
import Logo from 'components/Logo';
import React from 'react';
import styles from './styles.module.scss';

export default function SurveyError({ error }) {
  return (
    <Card className={styles.ErrorCard}>
      <Logo className={styles.ErrorLogo} />
      <div className={styles.ErrorContent}>
        <h3>Oops!</h3>
        <p>Something went wrong.</p>
        <p>{error}</p>
        <ArrowButton to={APP_PATHS.root} icon="close" />
      </div>
    </Card>
  );
}
