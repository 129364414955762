import isEmpty from 'lodash.isempty';
import { __DEVELOPMENT__ } from './data';
import { randomColor } from './random';

/**
 * Console Wrapper
 * - Logs are stripped out in producution builds
 * - Logs prefixed with [SomePrefix] are colorized
 */
const SESSION_CACHE_KEY = '__LOG_COLORS';
const PREFIX_REGEX = /^\[.+\]/;

const METADATA = {};
const OUTPUT = __DEVELOPMENT__ || process.env.REACT_APP__DEBUG;

/**
 * @returns {null||Object} Colors stored in Session
 */
const getSessionColors = () => {
  const sessionColors =
    window.sessionStorage && window.sessionStorage.getItem(SESSION_CACHE_KEY);
  return sessionColors && sessionColors !== null && JSON.parse(sessionColors);
};

/**
 * @returns {Boolean} true
 */
const setSessionColors = () => {
  window.sessionStorage &&
    window.sessionStorage.setItem(SESSION_CACHE_KEY, JSON.stringify(COLORS));

  return true;
};

const COLORS = getSessionColors() || {};

/**
 * Metadata appended to every log
 * @param {Object} data
 */
export const appendLogMetadata = (data) =>
  Object.keys(data).forEach((key) => {
    if (!METADATA[key]) {
      METADATA[key] = data[key];
    }
  });

/**
 * Randomly and consistently assign a color to a logging key
 * @param {String} key
 */
const assignColor = (key) => COLORS[key] || (COLORS[key] = randomColor());

/**
 * Determine if the log can be colorized
 * @param  {...any} args
 */
const prepareArgs = (...args) => {
  if (args[0] && typeof args[0] === 'string') {
    let prefix = args[0].match(PREFIX_REGEX);
    if (prefix && prefix[0]) {
      let prefixStr = prefix[0];
      let remainingStr = args.shift().replace(prefix[0], '').trim();

      let preparedArgs = [
        `%c ${prefixStr}`,
        `color: ${assignColor(prefixStr)}`,
      ];

      if (remainingStr) {
        preparedArgs.push(remainingStr);
      }

      args.unshift(...preparedArgs);
    }
  }

  // Include METADATA if set
  !isEmpty(METADATA) && args.push({ meta: METADATA });

  // Remember Colors for Session
  setSessionColors();

  return args;
};

/**
 * Log
 * @param  {...any} args
 */
export const log = (...args) => OUTPUT && console.log(...prepareArgs(...args));

/**
 * Info
 * @param  {...any} args
 */
export const info = (...args) =>
  OUTPUT && console.info(...prepareArgs(...args));

/**
 * Warn
 * @param  {...any} args
 */
export const warn = (...args) =>
  OUTPUT && console.warn(...prepareArgs(...args));

/**
 * Notice
 * @param  {...any} args
 */
export const notice = (...args) =>
  OUTPUT && console.notice(...prepareArgs(...args));

/**
 * Error
 * @param  {...any} args
 */
export const error = (...args) =>
  OUTPUT && console.error(...prepareArgs(...args));

/**
 * Debug
 * @param  {...any} args
 */
export const debug = (...args) =>
  OUTPUT && console.debug(...prepareArgs(...args));

/**
 * Table
 * @param  {...any} args
 */
export const table = (...args) =>
  OUTPUT && console.table(...prepareArgs(...args));
