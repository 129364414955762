import ACTIONS from 'common/lib/actions';
import { DEBOUNCE_RATE } from 'common/lib/data';
import { useStateValue } from 'common/lib/state';
import { avoidWidows, slugify } from 'common/lib/strings';
import { useGoToNextStep } from 'common/lib/surveyHelpers';
import Card from 'components/Card';
import { useField } from 'formik';
import React from 'react';
import styles from './styles.module.scss';

const LABEL_STRONGLY_DISAGREE = 'Strongly Disagree';
const LABEL_DISAGREE = 'Disagree';
const LABEL_NEUTRAL = 'Neutral';
const LABEL_AGREE = 'Agree';
const LABEL_STRONGLY_AGREE = 'Strongly Agree';

const CHOICES = {
  strongly_disagree: LABEL_STRONGLY_DISAGREE,
  disagree: LABEL_DISAGREE,
  neutral: LABEL_NEUTRAL,
  agree: LABEL_AGREE,
  strongly_agree: LABEL_STRONGLY_AGREE,
};

/**
 * Prepare Label
 * - Inject spans to prevent widows
 * @param {Object} Question
 */
const prepareLabel = ({ label }) => avoidWidows(label);

/**
 * Prepare Option Inputs
 * @param {Object} Question
 */
const prepareOptions = ({ question: { label, slug }, goToNextStep, id }) =>
  Object.keys(CHOICES).map((value) => {
    const label = CHOICES[value];
    const optionSlug = slugify(`${slug}-${value}`);
    const [field, meta, helpers] = useField(slug); // eslint-disable-line no-unused-vars
    const [ state, dispatch] = useStateValue(); // eslint-disable-line no-unused-vars
    return (
      <div className={styles.QuestionOption} key={optionSlug}>
        <input
          className={styles.QuestionOptionInput}
          type="radio"
          id={optionSlug}
          name={slug}
          value={value}
          checked={value === field.value}
          onClick={() => {
            setTimeout(goToNextStep, DEBOUNCE_RATE);
            dispatch({
              type: ACTIONS.UPDATE_LAST_STEP,
              value: id,
            });
          }}
          onChange={() => {
            helpers.setValue(value);
          }}
        />
        <label className={styles.QuestionOptionLabel} htmlFor={optionSlug}>
          {label}
        </label>
      </div>
    );
  });

export default function SurveyQuestion({ uid, id }) {
  const goToNextStep = useGoToNextStep({ uid });
  const [{ questions }] = useStateValue();
  const question = questions[id];
  return (
    (question && (
      <Card className={styles.Question}>
        <div className={styles.QuestionBody}>
          <div className={styles.QuestionLabel}>{prepareLabel(question)}</div>
          <div className={styles.QuestionOptions}>
            {prepareOptions({ question, goToNextStep, id})}
          </div>
        </div>
      </Card>
    )) ||
    null
  );
}

SurveyQuestion.propTypes = {};
