import get from 'lodash.get';
import merge from 'lodash.merge';
import ACTIONS, { validateActionType } from './actions';
import { APP_NAME, INITIAL_STATE } from './data';
import { info } from './log';
import { clearPersistedState, persistState } from './stateHelpers';
import { buildSequence, questionId } from './surveyHelpers';

const reducer = (state, action) => {
  validateActionType(action.type);

  info(`[${APP_NAME}:ACTION]`, { ...action });

  const nextState = (() => {
    switch (action.type) {
      case ACTIONS.SET_COLOR_SCHEME:
        return {
          ...state,
          scheme: action.scheme,
          isChangingScheme: true,
        };
      case ACTIONS.HANDLE_GET_SURVEY:
        return {
          ...state,
          survey: merge({ ...state.survey }, action.response.data.attributes),
        };
      case ACTIONS.HANDLE_SUBMIT_SUCCESS:
      case ACTIONS.HANDLE_INVITE_SUCCESS:
      case ACTIONS.HANDLE_REFERRAL_SUCCESS:
        const founder =
          (action.response.data.type === 'founder' &&
            action.response.data.attributes) ||
          {};
        const included = action.response.included || [];
        const survey =
          (included.find((el) => el.type === 'bigFiveSurvey') || {})
            .attributes || {};
        const referredFounderId = get(
          action.response,
          'data.relationships.referredFounder.data.id',
          null
        );
        const referredFounder = (
          (referredFounderId &&
            included.find(
              (el) => el.type === 'founder' && el.id === referredFounderId
            )) ||
          {}
        ).attributes;
        const questions = (survey.questions || []).reduce((data, question) => {
          data[questionId(question)] = question;
          return data;
        }, {});

        return {
          ...state,
          founder: merge({ ...state.founder }, founder),
          survey: merge({ ...state.survey }, survey),
          referredFounder: merge({ ...state.referredFounder }, referredFounder),
          questions: merge(
            {
              ...state.questions,
            },
            questions
          ),
          sequence: [
            ...state.sequence,
            ...buildSequence({ questions: survey.questions || [] }),
          ],
          isSequenceReady: true,
        };
      case ACTIONS.UPDATE_RESULTS_PAGE:
        return {
          ...state,
          resultsPage: {
            ...state.resultsPage,
            ...action.resultsPage,
          },
        };
      case ACTIONS.HANDLE_INVITE_ERROR:
        return {
          ...state,
          inviteError: action.inviteError,
        };
      case ACTIONS.CLEAR_INVITE_ERROR:
        return {
          ...state,
          inviteError: null,
        };
      case ACTIONS.RESET_SURVEY:
        return {
          ...INITIAL_STATE,
        };
      case ACTIONS.UPDATE_LAST_STEP:
        return {
          ...state,
          lastRecordedStep: action.value,
        };
      default:
        return state;
    }
  })();

  // Keep a fresh copy of application state in localStorage
  // Unless the action is destructive (this array).
  [ACTIONS.CLEAR_INVITE_ERROR, ACTIONS.RESET_SURVEY].includes(action.type)
    ? clearPersistedState()
    : persistState(nextState);

  return nextState;
};

export default reducer;
