import { trackEvent } from 'common/lib/analytics';
import {
  getStepRoute,
  useNextStep,
  useSequence,
  useStep,
} from 'common/lib/surveyHelpers';
import ArrowButton from 'components/ArrowButton';
import Card from 'components/Card';
import React from 'react';
import { Link } from 'react-router-dom';
import Art from './Art';
import { INTERMISSIONS } from './intermissions';
import styles from './styles.module.scss';

const IntermissionProgress = ({ number }) => (
  <div className={styles.progress} data-number={number}>
    {Array(INTERMISSIONS_TOTAL)
      .fill(true)
      .map((el, index) => (
        <i
          key={index}
          className={index <= number - 1 ? styles.filled : null}
        ></i>
      ))}
  </div>
);

const IntermissionHeading = ({ children }) => (
  <h2 className={styles.IntermissionHeading}>{children}</h2>
);
const IntermissionSubheading = ({ children }) => (
  <div className={styles.IntermissionSubheading}>{children}</div>
);

const Intermission = ({
  id,
  heading,
  subheading,
  nextStep,
  sequence,
  callback,
}) => {
  React.useEffect(() => {
    trackEvent(trackEvent.EVENT__INTERMISSION_VIEWED, { id });
    callback && callback();
  }, [callback, id]);

  return (
    <Link
      className={styles.IntermissionLink}
      to={getStepRoute({ step: nextStep, sequence })}
    >
      <IntermissionHeading>{heading}</IntermissionHeading>
      <IntermissionSubheading>{subheading}</IntermissionSubheading>
      <ArrowButton />
    </Link>
  );
};

const INTERMISSIONS_TOTAL = Object.keys(INTERMISSIONS).length;

const SurveyIntermission = ({ uid }) => {
  const currentStep = useStep({ uid });
  const sequence = useSequence();
  const nextStep = useNextStep({ uid });
  const [showArt, setShowArt] = React.useState(false);

  const number = currentStep.id.split('--').pop() || 0;
  const { ...props } = INTERMISSIONS[currentStep.id];

  React.useEffect(() => {
    if (showArt === false) {
      setShowArt(true);
    }
  }, [showArt]);

  return (
    <Card className={styles.Intermission}>
      <Art isVisible={showArt} />
      <IntermissionProgress number={number} />
      <Intermission
        id={currentStep.id}
        sequence={sequence}
        nextStep={nextStep}
        {...props}
      />
    </Card>
  );
};

export default SurveyIntermission;
