import { Button } from '@material-ui/core';
import cx from 'classnames';
import ACTIONS from 'common/lib/actions';
import { trackEvent } from 'common/lib/analytics';
import api from 'common/lib/api';
import {
  APP_FEEDBACK_FORM_URL,
  APP_IFRAME_PRINT_DELAY,
  APP_PATHS,
} from 'common/lib/data';
import { useGenerateFullPath } from 'common/lib/hooks/useGenerateFullPath';
import { Desktop, Mobile } from 'common/lib/responsive';
import { useStateValue } from 'common/lib/state';
import { avoidWidows } from 'common/lib/strings';
import Logo from 'components/Logo';
import Stack from 'components/Stack';
import AltLogo from 'images/logo.svg';
import PrintIcon from 'images/print.svg';
import ShareIcon from 'images/share.svg';
import isEmpty from 'lodash.isempty';
import React from 'react';
import { Link } from 'react-router-dom';
import prepareChartData from './common/prepareChartData';
import Card from './components/Card';
import Details from './components/Details';
import PrintIframe from './components/PrintIframe';
import Radar from './components/Radar';
import Referral from './components/Referral';
import Share from './components/Share';
import styles from './styles.module.scss';

const Header = ({ children }) => (
  <div className={styles.heading}>
    <img className={styles.logo} src={AltLogo} alt="Human Logo" />
    {children}
    <Desktop>
      <Button className={cx(styles.textButton, styles.logoutIcon)}>
        <Link to={APP_PATHS.logout}>Log out</Link>
      </Button>
    </Desktop>
  </div>
);

const SubHeader = ({ handlePrint, handleRefer, handleShare }) => {
  const [
    {
      founder,
      referredFounder,
      resultsPage: { canRefer },
    },
  ] = useStateValue();

  return (
    <div className={styles.subheader}>
      <div className={styles.subtitle}>
        <div> Human Founder Assessment </div>
        <div className={styles.founderName}>{founder.fullName}</div>
      </div>
      <div className={styles.iconContainer}>
        {canRefer && founder.canInvite && handleRefer && (
          <div className={styles.iconGroup} onClick={handleRefer}>
            <img
              className={styles.shareIcon}
              src={ShareIcon}
              alt="Refer Icon"
            />
            Refer
          </div>
        )}

        {referredFounder.fullName && (
          <div className={styles.iconGroup} onClick={handleShare}>
            <img
              className={styles.shareIcon}
              src={ShareIcon}
              alt="Share Icon"
            />
            Share
          </div>
        )}

        <div className={styles.iconGroup} onClick={handlePrint}>
          <img className={styles.printIcon} src={PrintIcon} alt="Print Icon" />
          Print
        </div>
        <Mobile>
          <div className={styles.iconGroup}>
            <Button className={cx(styles.textButton, styles.printIcon)}>
              <Link to={APP_PATHS.logout}>Log out</Link>
            </Button>
          </div>
        </Mobile>
      </div>
    </div>
  );
};

const RightSection = () => {
  const [
    {
      survey: { evaluation },
    },
  ] = useStateValue();
  return (
    <div className={styles.rightSectionContainer}>
      <span className={styles.traitsTitle}>Your traits</span>
      <p className={styles.traitsSubtitle}>Radar</p>
      <Chart evaluation={evaluation} />
    </div>
  );
};

const Footer = () => (
  <div className={styles.footer}>
    <Logo className={styles.logoBottom} />
    <h4 className={styles.logoText}>Human Ventures</h4>
    <Button
      className={styles.feedback}
      href={APP_FEEDBACK_FORM_URL}
      target="_feedback"
      rel="noopener"
      disableRipple
    >
      Give us feedback
    </Button>
  </div>
);

export default function Results() {
  const [
    {
      founder,
      survey: { evaluation },
      resultsPage: { detailsTrait, showPrintIframe, showShare, showReferral },
    },
    dispatch,
  ] = useStateValue();

  const { canInvite } = founder;

  React.useEffect(() => {
    onUnloadPrintIframe();
    clearDetailsTrait();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePage = React.useCallback(
    (props) =>
      dispatch({
        type: ACTIONS.UPDATE_RESULTS_PAGE,
        resultsPage: props,
      }),
    [dispatch]
  );

  // Trait Details Modal
  const setDetailsTrait = (detailsTrait) =>
    updatePage({
      detailsTrait,
    });
  const clearDetailsTrait = () =>
    updatePage({
      detailsTrait: null,
    });

  React.useEffect(() => {
    // Re-fetch Survey if evaluation is missing
    if (isEmpty(evaluation)) {
      api.getSurvey(founder.token).then((response) => {
        dispatch({
          type: ACTIONS.HANDLE_GET_SURVEY,
          response: response.parsed,
        });
      });
    }
  }, [dispatch, evaluation, founder.token, updatePage]);

  // Print Version HREF
  const generateFullPath = useGenerateFullPath();
  const printHref = generateFullPath({ path: APP_PATHS.printResults });

  const onUnloadPrintIframe = () =>
    showPrintIframe &&
    updatePage({
      showPrintIframe: false,
    });
  const onLoadPrintIframe = () =>
    !showPrintIframe &&
    updatePage({
      showPrintIframe: true,
    });
  const onPrint = () => {
    onUnloadPrintIframe();
    setTimeout(onLoadPrintIframe, APP_IFRAME_PRINT_DELAY);
  };

  const DesktopTraitsSection = () => {
    return (
      <div className={styles.traitsSection}>
        <LeftSection />
        {!detailsTrait ? (
          <RightSection />
        ) : (
          <Details
            onHideDetails={clearDetailsTrait}
            detailsTrait={detailsTrait}
            {...evaluation[detailsTrait]}
          />
        )}
      </div>
    );
  };

  const MobileTraitsSection = () => {
    return (
      <div className={styles.traitsSection}>
        {!detailsTrait ? (
          <>
            <LeftSection />
            <RightSection />
          </>
        ) : (
          <Details
            onHideDetails={clearDetailsTrait}
            detailsTrait={detailsTrait}
            {...evaluation[detailsTrait]}
          />
        )}
      </div>
    );
  };

  const LeftSection = () => {
    return (
      <div className={styles.leftSectionContainer}>
        <span className={styles.traitsTitle}>Your traits at a glance</span>
        <p className={styles.traitsSubtitle}>
          Open any trait below to learn more.
        </p>
        {Object.keys(evaluation).map((trait) => (
          <Card
            key={trait}
            type={trait}
            onClick={() => setDetailsTrait(trait)}
            {...evaluation[trait]}
          />
        ))}
      </div>
    );
  };

  // Referral Modal
  const onHideReferral = () =>
    updatePage({
      showReferral: false,
    });
  const onShowReferral = () => {
    canInvite &&
      updatePage({
        showReferral: true,
      });
    trackEvent(trackEvent.EVENT__MODAL_OPEN_REFER);
  };

  // Share Modal
  const onHideShare = () =>
    updatePage({
      showShare: false,
    });
  const onShowShare = () => {
    updatePage({
      showShare: true,
    });
    trackEvent(trackEvent.EVENT__MODAL_OPEN_SHARE);
  };

  return (
    evaluation && (
      <Stack className={styles.stack}>
        <Header />
        <SubHeader
          handlePrint={onPrint}
          handleRefer={onShowReferral}
          handleShare={onShowShare}
        />
        <Desktop>
          <DesktopTraitsSection />
        </Desktop>
        <Mobile>
          <MobileTraitsSection />
        </Mobile>
        <Footer />
        <Referral
          show={showReferral}
          onClose={onHideReferral}
          onSuccess={onShowShare}
        />
        <Share show={showShare} onClose={onHideShare} />
        {showPrintIframe && (
          <PrintIframe
            load={showPrintIframe}
            src={printHref}
            onUnload={onUnloadPrintIframe}
          />
        )}
      </Stack>
    )
  );
}

export const Subheading = ({ heading }) => (
  <h3 className={styles.ResultsSubheading}>{avoidWidows(heading)}</h3>
);

export const Chart = ({ evaluation }) => (
  <>
    <div className={styles.chart}>
      <Desktop>
        <Radar {...prepareChartData(evaluation)} />
      </Desktop>
      <Mobile>
        <Radar {...prepareChartData(evaluation)} width={385} height={385} />
      </Mobile>
    </div>
  </>
);
