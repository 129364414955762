import { useStateValue } from 'common/lib/state';
import { avoidWidows } from 'common/lib/strings';
import {
  getStepRoute,
  useFirstStep,
  useLastStep,
} from 'common/lib/surveyHelpers';
import ArrowButton from 'components/ArrowButton';
import Card from 'components/Card';
import Logo from 'components/Logo';
import React from 'react';
import styles from './styles.module.scss';

const Completed = ({ firstName, lastName }) => (
  <>
    <h3>
      {avoidWidows(
        `Welcome back, ${firstName}. We're excited to see you again.`
      )}
    </h3>
    <div>{avoidWidows(`Tap the arrow to return to your results.`)}</div>
  </>
);

const Started = ({ firstName, lastName }) => (
  <>
    <h3>{avoidWidows(`Hello, ${firstName}, we’re glad you’re here.`)}</h3>
    <div>
      {avoidWidows(
        `After working with founders for years, we’re ready to release our Human Founder Assessment™ to help founders better understand themselves as they begin or continue the entrepreneurial journey.`
      )}
    </div>
  </>
);

export default function SurveyStart() {
  const firstStep = useFirstStep();
  const lastStep = useLastStep();

  const [{ founder, survey, sequence }] = useStateValue(); // eslint-disable-line no-empty-pattern

  const { firstName, lastName } = founder;
  const { isCompleted } = survey;

  const to = !isCompleted
    ? getStepRoute({ step: firstStep, sequence })
    : getStepRoute({ step: lastStep, sequence });

  const StartDescription = !isCompleted ? Started : Completed;

  return (
    (to && (
      <Card className={styles.StartCard}>
        <Logo className={styles.StartLogo} />
        <div className={styles.StartDescription}>
          <StartDescription firstName={firstName} lastName={lastName} />
        </div>
        <div className={styles.StartFormField}>
          <ArrowButton to={to} />
        </div>
      </Card>
    )) ||
    null
  );
}
