import { SCHEME_PRINT } from 'common/lib/data';
import { useStateValue } from 'common/lib/state';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyPrintResults from 'components/SurveyPrintResults';
import React from 'react';

const PrintResultsPage = () => {
  useLayoutScheme(SCHEME_PRINT);
  const [{ founder }] = useStateValue();

  return (
    <>
      <SEO title={`${founder.firstName}'s Results`} />
      <SurveyPrintResults />
    </>
  );
};
export default PrintResultsPage;
