import cx from 'classnames';
import Glass from 'components/Glass';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styles from './styles.module.scss';

const Modal = ({ className, show, onClose, children }) => {
  const dismissModal = () => {
    onClose && onClose();
  };

  return (
    <AnimatePresence>
      {show && (
        <div className={styles.overlay}>
          <Glass onClick={dismissModal} isActive />
          <motion.div
            className={cx(styles.container, className)}
            initial={{
              scale: 0.85,
              y: '100%',
            }}
            animate={{
              scale: [1.1, 1],
              y: '0%',
            }}
            exit={{
              scale: 0.85,
              y: '100%',
            }}
            transition={{ duration: 0.5 }}
          >
            {children}
          </motion.div>
          <div onClick={dismissModal} className={styles.close}>
            Close
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
