import { SCHEME_ERROR } from 'common/lib/data';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyError from 'components/SurveyError';
import React from 'react';

const ErrorPage = ({ error, componentStack, resetErrorBoundary }) => {
  useLayoutScheme(SCHEME_ERROR);

  return (
    <>
      <SEO title="Something went wrong" />
      <SurveyError error={error} />
    </>
  );
};

export default ErrorPage;
