import cx from 'classnames';
import { useStateValue } from 'common/lib/state';
import React from 'react';
import styles from './styles.module.scss';

const Layout = ({ children }) => {
  const [{ scheme }] = useStateValue();

  return (
    <>
      <div className={cx(styles[scheme || 'dark'], styles.Layout)}>
        {children}
      </div>
    </>
  );
};

export default Layout;
