import { SCHEME_DARK } from 'common/lib/data';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyStart from 'components/SurveyStart';
import React from 'react';

const StartPage = () => {
  useLayoutScheme(SCHEME_DARK);

  return (
    <>
      <SEO title="Hello" />
      <SurveyStart />
    </>
  );
};

export default StartPage;
