import cx from 'classnames';
import { GROUP_SIZE } from 'common/lib/data';
import {
  getStepRoute,
  usePreviousStep,
  useSequence,
  useStep,
} from 'common/lib/surveyHelpers';
import Logo from 'components/Logo';
import Progress from 'components/Progress';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styles from './styles.module.scss';

const Controls = ({ match }) => {
  const [show, setShow] = React.useState(false);
  const currentStep = useStep({ uid: match.params.step });
  const previousStep = usePreviousStep({ uid: currentStep.uid });
  const sequence = useSequence();

  const progressValue = currentStep.index || 0;
  const progressLabel = progressValue
    ? `${progressValue}/${GROUP_SIZE}`
    : 'Intermission';

  React.useEffect(() => {
    !show && setTimeout(() => setShow(true), 500);
  }, [show]);

  return (
    <div
      className={cx(styles.Controls, { [styles.show]: show })}
      title="&larr; Go Back"
    >
      <Link
        to={getStepRoute({ step: previousStep, sequence })}
        className={styles.ControlsBack}
      >
        <svg
          className={styles.ControlsBackSVG}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 18"
        >
          <path
            stroke="#000"
            strokeLinecap="square"
            d="M1.151 8.847l7.26 7.633M1.151 8.785l7.26-7.633"
          />
        </svg>
      </Link>
      <Progress
        max={GROUP_SIZE}
        value={progressValue}
        className={styles.ControlsProgress}
        label={progressLabel}
      />
      <Logo className={styles.ControlsLogo} />
    </div>
  );
};

export default withRouter(Controls);
