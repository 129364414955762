import { useStateValue } from 'common/lib/state';
import { avoidWidows } from 'common/lib/strings';
import { getStepRoute } from 'common/lib/surveyHelpers';
import ArrowButton from 'components/ArrowButton';
import Card from 'components/Card';
import FormField from 'components/FormField';
import Logo from 'components/Logo';
import { useField } from 'formik';
import React from 'react';
import styles from '../SurveyStart/styles.module.scss';

export default function SurveyInstructions() {
  const [{ sequence, lastRecordedStep }] = useStateValue(); // eslint-disable-line no-empty-pattern
  const [field, meta, helpers] = useField('terms'); // eslint-disable-line no-unused-vars
  const lastStepIndex = sequence.findIndex((item) => item.id === lastRecordedStep);
  const initialStep = (lastStepIndex > -1) ? lastStepIndex : 2;
  const to = getStepRoute({ step: sequence[initialStep], sequence });

  return (
    (to && (
      <Card className={styles.StartCard}>
        <Logo className={styles.StartLogo} />
        <div className={styles.StartDescription}>
          <p>
            {avoidWidows(
              `We encourage you to find a quiet space while taking time to answer each question with intentionality. This ensures an accurate gauge of your motivations, perceptions, strengths and weaknesses.`
            )}
          </p>
          <p>{avoidWidows(`This assessment will take 10 minutes. Please complete the survey in one sitting for accurate results.`)}</p>
        </div>
        <div className={styles.StartFormField}>
          <p>
            {avoidWidows(
              `By checking this box, you agree to Human Ventures' terms & privacy policy.`
            )}
            <a
              href="https://humanventures.co/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more about it here.
            </a>
          </p>

          <FormField
            className={styles.terms}
            id="terms"
            label={'Check this box to accept'}
            required
            type="checkbox"
            checked={field.value}
          >
            <ArrowButton to={to} />
          </FormField>
        </div>
      </Card>
    )) ||
    null
  );
}
