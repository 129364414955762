import md5 from 'blueimp-md5';
import isEmpty from 'lodash.isempty';
import { FullStoryAPI } from 'react-fullstory';
import { generatePath, useHistory } from 'react-router-dom';
import ACTIONS from './actions';
import airbrake from './airbrake';
import { trackIdent } from './analytics';
import apiPublic from './apiPublic';
import { chunk, shuffle } from './array';
import { APP_PATHS, GROUP_SIZE } from './data';
import { useStateValue } from './state';

const getStepIndex = (sequence, uid) =>
  sequence.findIndex((step) => step.uid === uid);

export const questionId = ({ id }) => `question--${id}`;
export const questionGroupId = (id) => `group--${id}`;
export const questionIntermissionId = (id) => `intermission--${id}`;

const sequenceHash = (page, id) => md5(JSON.stringify({ page, id }));

const sequenceItem = (page, id, index) => ({
  page,
  id,
  index,
  uid: sequenceHash(page, id),
});

export const redeem = ({ code, dispatch, callback = () => {} }) => {
  apiPublic
    .postRedeem({ code })
    .then((response) => {
      callback(response);
      trackIdent(code, { ...response.parsed.data.attributes });
      FullStoryAPI('identify', code, { ...response.parsed.data.attributes });
      dispatch({
        type: ACTIONS.HANDLE_INVITE_SUCCESS,
        response: response.parsed,
      });
    })
    .catch((err, response) => {
      airbrake.notify(err);
      dispatch({
        type: ACTIONS.HANDLE_INVITE_ERROR,
        inviteError: {
          message: err.message,
          status: err.response && err.response.status,
          data: err.response && err.response.parsed,
        },
      });
    });
};

export const buildSequence = ({ questions }) => {
  const sequence = chunk(shuffle(questions), GROUP_SIZE).reduce(
    (groups, chunk, groupIndex) => {
      groups.push(
        chunk.reduce(
          (set, question, questionIndex) => {
            return [
              sequenceItem(
                'question',
                questionId(question),
                GROUP_SIZE - questionIndex
              ),
              ...set,
            ];
          },
          [sequenceItem('intermission', questionIntermissionId(1 + groupIndex))]
        )
      );

      return groups.flat();
    },
    [sequenceItem('start'), sequenceItem('instructions')]
  );

  sequence.push(sequenceItem('results'));

  return sequence;
};

export const getStepRoute = ({ step, sequence = [] }) => {
  switch (!isEmpty(step)) {
    case step.page === 'start':
      return {
        pathname: generatePath(APP_PATHS.start),
        state: {
          previous: null,
          next: sequence[1],
        },
      };
    case step.page === 'instructions':
      return {
        pathname: generatePath(APP_PATHS.instructions),
        state: {
          previous: null,
          next: sequence[2],
        },
      };
    case step.page === 'results':
      return {
        pathname: generatePath(APP_PATHS.results),
        state: {
          previous: null,
          next: sequence[sequence.length - 2],
        },
      };
    case !!step.page:
      return {
        pathname: generatePath(APP_PATHS.survey, { step: step.uid }),
        state: {
          previous: null,
          next: null,
        },
      };
    default:
      return {
        pathname: APP_PATHS.error,
        state: {
          previous: null,
          next: null,
        },
      };
  }
};

export const useSequence = () => {
  const [{ sequence }] = useStateValue();

  return sequence;
};

/**
 * Manually Navigate to any step
 */
export const useGoToStep = ({ step }) => {
  const history = useHistory();
  const sequence = useSequence();

  return () => history.push(getStepRoute({ step, sequence }));
};

/**
 * Manually navigate to the "next" step relative to the one passed
 */
export const useGoToNextStep = ({ uid }) => {
  const step = useNextStep({ uid });

  return useGoToStep({ step });
};

export const useGoToPreviousStep = ({ uid }) => {
  const step = usePreviousStep({ uid });

  return useGoToStep({ step });
};

export const useStep = ({ uid }) => {
  const sequence = useSequence();

  return sequence.find((step) => step.uid === uid);
};

export const useFirstStep = () => {
  const sequence = useSequence();

  return sequence[1];
};

export const useLastStep = () => {
  const sequence = useSequence();

  return sequence[sequence.length - 1];
};

export const useNextStep = ({ uid = null }) => {
  const sequence = useSequence();

  return uid
    ? sequence[getStepIndex(sequence, uid) + 1]
    : sequence[sequence.length - 1];
};

export const usePreviousStep = ({ uid = null }) => {
  const sequence = useSequence();

  return uid ? sequence[getStepIndex(sequence, uid) - 1] : sequence[0];
};
