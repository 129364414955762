/**
 * ============================================================================
 * Static Data
 * ============================================================================
 */

/**
 * Environment
 */
export const __DEVELOPMENT__ = process.env.REACT_APP__IS_DEVELOPMENT === 'true';
export const __PRODUCTION__ = process.env.REACT_APP__IS_PRODUCTION === 'true';
export const __TEST__ = process.env.REACT_APP__IS_TEST === 'true';
export const __ENV__ = __DEVELOPMENT__
  ? 'development'
  : __TEST__
  ? 'test'
  : 'production';

/**
 * Framework / Utility
 */
export const NOOP_FN = () => {};
export const KEYCODE_ESC = 27;
export const DEBOUNCE_RATE = 300;
export const ONE_SECOND = 1000;
/**
 * Application
 */
export const APP_NAME = 'Founder Survey';
export const APP_CACHE_KEY = `--human-app--${process.env.REACT_APP__COMMIT_REF}`;
export const APP_FORM_NAME = 'survey';
export const APP_FORM_CACHE_KEY = `--human-app-form--${process.env.REACT_APP__COMMIT_REF}`;
export const APP_FOUNDER_API_HOST = process.env.REACT_APP__FOUNDER_API_HOST;
export const APP_PUBLIC_API_HOST = process.env.REACT_APP__PUBLIC_API_HOST;
export const APP_FEEDBACK_FORM_URL =
  'https://humanventuresstudio.typeform.com/to/LT8oUNwf';

export const APP_FEEDBACK_PROMPT_DELAY = 20 * ONE_SECOND; // 20 Seconds
export const APP_IFRAME_PRINT_DELAY = 0.5 * ONE_SECOND; // 1 Second

export const SCHEME_LIGHT = 'light';
export const SCHEME_DARK = 'dark';
export const SCHEME_ERROR = 'error';
export const SCHEME_PRINT = 'print';

export const APP_PATHS = {
  start: '/start',
  instructions: '/start/instructions',
  results: '/results',
  printResults: '/results/print',
  survey: '/survey/:step',
  notFound: '/not-found',
  inviteError: '/oops',
  error: '/error',
  logout: '/logout',
  root: '',
};

/**
 * Content: Questions
 */
export const GROUP_SIZE = 10;

export const INITIAL_STATE = {
  scheme: SCHEME_DARK,
  isSequenceReady: false,
  inviteError: null,
  questions: {},
  sequence: [],
  lastRecordedStep: null,
  // Populated by the API ----
  founder: {},
  survey: {},
  referredFounder: {},
  resultsPage: {
    canPrint: true,
    canRefer: true,
    detailsTrait: null,
    showFeedback: false,
    showFeedbackTimeout: null,
    showPrintIframe: false,
    showReferral: false,
    showShare: false,
  },
};

/**
 * Form
 */
export const INITIAL_VALUES = [].reduce((values, question) => {
  values[question.slug] = '';
  return values;
}, {});

// Add email
INITIAL_VALUES['email'] = '';
INITIAL_VALUES['first_name'] = '';
INITIAL_VALUES['last_name'] = '';
INITIAL_VALUES['code'] = '';
INITIAL_VALUES['terms'] = '';

Object.freeze(INITIAL_STATE);
