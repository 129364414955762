import React from 'react';
import { warn } from './log';

/**
 * ============================================================================
 * Segment Analytics Wrapper
 * @link https://segment.com/
 * ============================================================================
 */

const METADATA = {};

/**
 * @param  {...any} args Fallback Function when Segment is not loaded
 */
const FN_FALLBACK = (...args) => {
  warn('[analytics]', '[NOT LOADED!!!]', ...args);
};

/**
 * @returns {Object} Segment Analytics.js || Fallback for Dev
 */
const analytics = () =>
  (typeof window !== `undefined` && window.analytics) || {
    page: FN_FALLBACK,
    event: FN_FALLBACK,
  };

/**
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
 * @param {object} data
 */
export const trackPage = (data = {}) => {
  // info(`[analytics]`, 'page', data);
  analytics().page({ ...data, ...METADATA });
  return true;
};

/**
 * Higher order component wraps layout to track page
 * @param {Component} Component
 */
export const withPageTracking = (Component) => {
  function PageTrackingHOC(props) {
    React.useEffect(() => {
      trackPage();
    }, []);
    return <Component {...props} />;
  }
  return PageTrackingHOC;
};

/**
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 * @param {string} name
 * @param {object} data
 */
export const trackEvent = (name, data) => {
  // info(`[analytics]`, `event`, name, data);
  analytics().track(name, { ...data, ...METADATA });
  return true;
};

/**
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 * @param {string} name
 * @param {object} data
 */
export const trackIdent = (code, data) => {
  // info(`[analytics]`, `ident`, code, data);
  analytics().identify(code, { ...data, ...METADATA });
  return true;
};

/**
 * Metadata appended to every log
 * @param {Object} data
 */
export const appendAnalyticsMetadata = (data) =>
  Object.keys(data).forEach((key) => {
    if (!METADATA[key]) {
      METADATA[key] = data[key];
    }
  });

/**
 * Analytics Event Keys
 */
trackEvent.EVENT__CLICK_REFERRAL_MAILTO = 'click:referral:mailto';
trackEvent.EVENT__CLICK_HELP = 'click:help';
trackEvent.EVENT__MODAL_OPEN_SHARE = 'modal:open:share';
trackEvent.EVENT__MODAL_OPEN_REFER = 'modal:open:refer';
trackEvent.EVENT__MODAL_OPEN_FEEDBACK = 'modal:open:feedback';
trackEvent.EVENT__INTERMISSION_VIEWED = 'intermission:viewed';
