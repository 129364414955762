import Events from 'common/lib/events';
import Form from 'components/Form';
import Help from 'components/Help';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import routes from '../../routes';

export default function App() {
  const location = useLocation();
  const formRef = React.createRef();
  // Listen for External Submit Events
  Events.once(Events.EVENT.Submit, () => {
    formRef.current &&
      formRef.current.submitForm &&
      formRef.current.submitForm();
  });

  return (
    <Form ref={formRef}>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          {routes.map((route, i) => (
            <Route key={`${i}_${route.path}`} exact {...route} />
          ))}
        </Switch>
      </AnimatePresence>
      <Help />
    </Form>
  );
}
