import TextField from '@material-ui/core/TextField';
import cx from 'classnames';
import { slugify } from 'common/lib/strings';
import { useField } from 'formik';
import React from 'react';
import styles from './styles.module.scss';

const FormField = ({
  id,
  label,
  type = 'text',
  children,
  className,
  ...attrs
}) => {
  const [field, meta, helpers] = useField(id); // eslint-disable-line no-unused-vars
  const slug = slugify(`${id}-${label}`);

  return (
    <div className={cx(styles.FormField, className)} key={slug}>
      <input
        {...field}
        {...attrs}
        className={cx(styles.FormFieldInput)}
        id={slug}
        type={type}
        name={id}
      />
      {children ? (
        children
      ) : (
        <label className={styles.FormFieldLabel} htmlFor={slug}>
          {label}
        </label>
      )}
    </div>
  );
};

export default FormField;

export const MaterialFormField = ({ className, inputClassName, ...attrs }) => (
  <div className={cx(styles.MaterialFormField, className)}>
    <TextField
      className={cx(styles.MaterialFormFieldInput, inputClassName)}
      {...attrs}
      // variant="filled"
      // variant="outlined"
    />
  </div>
);
