import _ from 'lodash';
import React from 'react';

const defaultRadarRingsStyle = {
  fontFamily: 'sans-serif',
  fontSize: 10,
  ringOpacity: 0.1,
  textFill: 'black',
};

const offset = Math.PI;

const generatePoint = ({ length, angle }) => {
  const point = [
    length * Math.sin(offset - angle),
    length * Math.cos(offset - angle),
  ];
  return point;
};

const calculatePoints = (scaledTickValue, NUM_OF_SIDES) => {
  const polyangle = (Math.PI * 2) / NUM_OF_SIDES;
  const points = [];
  for (let vertex = 0; vertex < NUM_OF_SIDES; vertex++) {
    const theta = vertex * polyangle;
    points.push(generatePoint({ length: scaledTickValue, angle: theta }));
  }
  points.push(points[0]);
  return points;
};

export default function RadarLevels(props) {
  const { ticks, scale, axisColor, ringColor, format, style } = props;

  const { fontFamily, fontSize, textFill } = {
    ...defaultRadarRingsStyle,
    ...style,
  };
  const outerFirst = _.reverse(ticks);
  return (
    <g>
      {outerFirst.map((tickValue) => {
        return (
          <polygon
            key={`${tickValue}`}
            fillOpacity={1}
            fill={ringColor}
            stroke={axisColor}
            points={calculatePoints(scale(tickValue), ticks.length)}
          />
        );
      })}
      {outerFirst.map((tickValue) => {
        return (
          <text
            key={`${tickValue}-tick`}
            x={0}
            y={-scale(tickValue)}
            dx={'0.4em'}
            dy={'0.4em'}
            fontFamily={fontFamily}
            fontSize={fontSize}
            textAnchor={'left'}
            fill={textFill}
          >
            {format(tickValue)}
          </text>
        );
      })}
    </g>
  );
}
