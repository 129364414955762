import Iframe from 'components/Iframe';
import React from 'react';
import usePortal from 'react-useportal';
import styles from './styles.module.scss';

export default function PrintIframe({ load, src, onUnload }) {
  const { Portal } = usePortal();
  const [iframeWindow, setIframeWindow] = React.useState();
  const [iframeHeight, setIframeHeight] = React.useState();

  React.useEffect(() => {
    if (iframeWindow) {
      setTimeout(() => {
        setIframeHeight(iframeWindow.document.body.scrollHeight);
        iframeWindow.focus();
        iframeWindow.print();
        // Unload iframe after printing
        onUnload && setTimeout(onUnload, 5000);
      }, 2000);
    }
  }, [iframeWindow, onUnload]);

  /* Fancy print-in-line iframe without leaving the page */
  return (
    (load && (
      <Portal>
        <Iframe
          className={styles.printIframe}
          src={src}
          width="100%"
          height={iframeHeight}
          onLoad={(event) => {
            // Store a reference to the iframe window
            setIframeWindow(event.currentTarget.contentWindow);
          }}
        />
      </Portal>
    )) ||
    null
  );
}
