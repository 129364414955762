import Card from 'components/Card';
import React from 'react';
import styles from './styles.module.scss';

export default function SurveyLogout() {
  return (
    <Card className={styles.LogoutCard}>
      <h1>Logging Out...</h1>
    </Card>
  );
}
