import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import BackIcon from 'images/back.svg';
import { default as React } from 'react';
import DetailsCard from './DetailsCard';
import styles from './styles.module.scss';

const Details = ({
  detailsTrait,
  printable = false,
  onHideDetails,
  ...evaluation
}) => {
  if (!detailsTrait) return null;
  const traitsAndSubtraits = {
    [detailsTrait]: evaluation,
    ...evaluation.subtraits,
  };
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{
          opacity: 0,
          x: '25%',
        }}
        animate={{
          opacity: 1,
          x: '0%',
        }}
        exit={{
          opacity: 0,
          x: '25%',
        }}
        className={styles.detailsContainer}
      >
        <div
          id={`${detailsTrait}-details`}
          className={cx(styles.details, styles[detailsTrait])}
        >
          {!printable && (
            <div className={styles.backIcon} onClick={onHideDetails}>
              <img className={styles.backIcon} src={BackIcon} alt="Back Icon" />
              Back
            </div>
          )}
        </div>
        {Object.values(traitsAndSubtraits).map((trait, index) => (
          <DetailsCard
            key={index}
            isMainTrait={index === 0}
            trait={Object.keys(traitsAndSubtraits)[index]}
            printable={printable}
            {...trait}
          />
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

export default Details;
