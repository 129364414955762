import { curveCardinalClosed, radialLine } from 'd3-shape';
import Gradient from 'images/gradient.svg';
import _ from 'lodash';
import React from 'react';

const defaultCircleStyle = {
  selectedFillOpacity: 0.5,
  inactiveFillOpacity: 0.2,
  selectedStrokeOpacity: 1.0,
  inactiveStrokeOpacity: 0.7,
  pointRadius: 6.5,
  selectedPointFill: 'white',
  selectedPointOpacity: 1.0,
  inactivePointOpacity: 0.7,
};

export default function RadarCircle(props) {
  const {
    points,
    scales,
    offsetAngles,
    isSelected,
    color,
    style,
    dimensions,
  } = props;
  const {
    selectedStrokeOpacity,
    inactiveStrokeOpacity,
    pointRadius,
    selectedPointOpacity,
    inactivePointOpacity,
  } = { ...defaultCircleStyle, ...style };

  const lineFunction = radialLine()
    .radius((point) => scales[point.variableKey](point.value))
    .angle((point) => _.round(offsetAngles[point.variableKey], 6))
    .curve(curveCardinalClosed);

  const pathData = lineFunction(points);
  return (
    <>
      <defs>
        <mask id="gradient">
          <g
            transform={`translate(${dimensions.innerWidth / 2}, ${
              dimensions.innerHeight / 2
            })`}
          >
            <path
              d={pathData}
              stroke={color}
              strokeOpacity={
                isSelected ? selectedStrokeOpacity : inactiveStrokeOpacity
              }
            />
          </g>
        </mask>
      </defs>
      <image
        transform={`translate(${-dimensions.innerWidth / 2}, ${
          -dimensions.innerHeight / 2
        })`}
        href={Gradient}
        mask={'url(#gradient)'}
        alt="Gradient"
      />
      <g>
        {points.map((point) => {
          return (
            <circle
              key={point.key}
              r={pointRadius}
              cx={point.x}
              cy={point.y}
              opacity={isSelected ? selectedPointOpacity : inactivePointOpacity}
            />
          );
        })}
      </g>
    </>
  );
}
