import { SCHEME_DARK } from 'common/lib/data';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyInstructions from 'components/SurveyInstructions';
import React from 'react';

const InstructionsPage = () => {
  useLayoutScheme(SCHEME_DARK);

  return (
    <>
      <SEO title="Get Started" />
      <SurveyInstructions />
    </>
  );
};

export default InstructionsPage;
