import { camelize, decamelize } from 'humps';
import React from 'react';
import { chunk } from './array';

/**
 * @param {String} str
 */
export const capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * @param {String} str 'Hello World Foo Bar.' -> 'hello-world-foo-bar'
 * @returns {String}
 */
export const slugify = (str) =>
  decamelize(camelize(str.replace(/[^\w\s]|_/g, ' ').replace(/\s+/g, ' ')), {
    split: /(?=[A-Z0-9])/, // Treat numbers as capital letters
    separator: '-',
  });

/**
 * @param {String} text
 * @param {String|CSSModule Reference} className
 * @returns {Array[node]}
 */
export const withParagraphs = (text, className = null) =>
  (text &&
    typeof text === 'string' &&
    text.split('\n').map((paragraph, index) => (
      <p key={index} className={className}>
        {paragraph}
      </p>
    ))) ||
  text;

/**
 * @param {String} str from: "title case" to "Title Case" Helper
 * @returns {String}
 */
export const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
  );

/**
 * @param {String} str
 * @returns {String}
 */
export const getPreview = (str, wordCount) =>
  str.split(' ').slice(0, wordCount).join(' ');

/**
 * Group words in spans to prevent widows
 * @param {String} str
 * @returns {String}
 */
export const avoidWidows = (str) =>
  chunk(str.split(' ').reverse(), 2)
    .map((pair, index) => (
      <span
        style={{ whiteSpace: 'nowrap', display: 'inline-block' }}
        key={index}
      >
        {pair.reverse().join(' ')}
        &nbsp;
      </span>
    ))
    .reverse()
    .flat();
