import React, { forwardRef, useImperativeHandle } from 'react';
import { Formik, FormikProps, FormikConfig } from 'formik';

/**
 * Formik 2.0 Wrapper to support useRef
 * @link https://github.com/jaredpalmer/formik/issues/1603#issuecomment-557540190
 * @param props
 * @param ref
 */
function FormikWithRef(props: FormikConfig<any>, ref: any) {
  let _formikProps: FormikProps<any>;

  useImperativeHandle(ref, () => _formikProps);

  return (
    <Formik {...props}>
      {(formikProps) => {
        _formikProps = formikProps;
        if (typeof props.children === 'function') {
          return props.children(formikProps);
        }
        return props.children;
      }}
    </Formik>
  );
}

export default forwardRef(FormikWithRef);
