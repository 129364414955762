import { SCHEME_LIGHT } from 'common/lib/data';
import Card from 'components/Card';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import React from 'react';

const NotFoundPage = () => {
  useLayoutScheme(SCHEME_LIGHT);

  return (
    <>
      <SEO title="Not Found" />
      <Card>
        <h1>404: Not Found</h1>
      </Card>
    </>
  );
};

export default NotFoundPage;
