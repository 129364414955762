import cx from 'classnames';
import { useStateValue } from 'common/lib/state';
import React from 'react';
import styles from './styles.module.scss';

const LogoSVG = ({ scheme }) => (
  <svg
    className={styles.LogoSVG}
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 307 457"
  >
    <path
      d="M266.3 86.7c13.3 21 21 45.9 21 72.5v125.4c0 9.1-.4 18.2-1.2 27.2l-1.5 16.2M70.7 226.3v-67.6c0-46 37.3-83.3 83.3-83.3 36.8 0 68 23.8 79.1 56.9 0 0 4.2 9.9 4.2 22.1 0 1.4.1 137.6.1 137.6 0 45.8-13.2 88.6-36 124.6M34.6 351.3c21.5-11.1 36.1-33.5 36.1-59.3v-35.4M131.8 423c33.6-32.7 54.7-78.1 55.5-128.5"
      className={styles.line}
    />
    <path
      d="M187.4 168.7v-11.5c-.6-18-15.3-32.3-33.4-32.3-18.4 0-33.4 15-33.4 33.4v10.6M54.1 154.4c-.1 1.5-.1 2.4-.1 3.9v133.3c0 18.9-10.5 35.4-25.9 43.9M194.9 67c-12.5-5.6-26.3-8.7-40.9-8.7-45.4 0-83.8 30.3-95.9 71.7M254 232.5v-74.2c0-32.1-15.1-60.6-38.6-78.9M228.2 402.3c16.5-33.4 25.8-71 25.8-110.7v-35.4"
      className={styles.line}
    />
    <path
      d="M137.3 212.7v-54.4c0-9.3 7.5-16.8 16.7-16.8 9.3 0 16.7 7.5 16.7 16.8v54.4M170.7 248.4v43.2c0 50.7-22.6 96.1-58.3 126.7M127.4 342.2c2.7-6.6 4.9-13.5 6.6-20.6 2.2-9.7 3.4-19.7 3.4-30l-.1-43.2M79.1 401.8c15.8-10.8 29.2-25 39.2-41.4M131.8 423c24.1-23.4 41.7-53.4 50.1-87.1M65.2 391c25.9-16 45.1-41.8 52.4-72.3 0 0 3.2-11.1 3.2-24.2"
      className={styles.line}
    />
    <path
      d="M127 116.2c-13.9 8.9-23 24.4-23 42.1l.1 79.2v54.1c0 37.6-20.7 70.3-51.4 87.5M153 425c25.4-28.4 42.8-64 48.8-103.5.5-3 .8-6 .9-9.1l1.3-20.8V158.3c0-27.6-22.4-50-50-50-2.5 0-4.9.2-7.2.5M75.8 334C68 347.3 56.6 358.1 43 365.3"
      className={styles.line}
    />
    <path
      d="M220.7 171.4v-13.1c0-36.8-29.9-66.7-66.7-66.7-36.8 0-66.7 29.9-66.7 66.7l.1 133.3c0 7.4-1 14.6-2.8 21.5M207.8 365.3c8.3-23 12.9-47.9 12.9-73.8v-92.7M176.3 423c8.8-11.6 16.5-24.1 22.9-37.3M30.7 107.6c-6.4 15.6-10 32.8-10 50.7v126.4M250.2 66.6c-24.1-25.5-58-41.4-95.7-41.6-48-.2-90.1 25-113.7 62.9M94.8 411c36-27.4 59.2-70.7 59.2-119.5v-43.2M154 160.3v52.4"
      className={styles.line}
    />
    <path
      d="M104.9 52.4c14.9-6.9 31.6-10.8 49.1-10.8 64.4 0 116.6 52.2 116.6 116.6v133.3c0 29.6-4.8 58.1-13.7 84.7M23.5 318.7c8.4-6.1 13.9-16 13.9-27.1V158.3c0-38.4 18.6-72.5 47.2-93.8"
      className={styles.line}
    />
    <circle cx="254" cy="258.6" r="5.5" className={styles.dot} />
    <circle cx="220.7" cy="200.8" r="5.5" className={styles.dot} />
    <circle cx="220.7" cy="169.4" r="5.5" className={styles.dot} />
    <circle cx="254" cy="230.1" r="5.5" className={styles.dot} />
    <circle cx="265.6" cy="85.9" r="5.5" className={styles.dot} />
    <circle cx="249.2" cy="64.5" r="5.5" className={styles.dot} />
    <circle cx="217.4" cy="80.6" r="5.5" className={styles.dot} />
    <circle cx="193.4" cy="65.4" r="5.5" className={styles.dot} />
    <circle cx="147.9" cy="108.6" r="5.5" className={styles.dot} />
    <circle cx="107" cy="51.3" r="5.5" className={styles.dot} />
    <circle cx="82.6" cy="65.9" r="5.5" className={styles.dot} />
    <circle cx="42.1" cy="86.2" r="5.5" className={styles.dot} />
    <circle cx="29.7" cy="110" r="5.5" className={styles.dot} />
    <circle cx="126.2" cy="116.2" r="5.5" className={styles.dot} />
    <circle cx="58.9" cy="127.7" r="5.5" className={styles.dot} />
    <circle cx="54" cy="157.1" r="5.5" className={styles.dot} />
    <circle cx="84.6" cy="311.2" r="5.5" className={styles.dot} />
    <circle cx="127.4" cy="340.2" r="5.5" className={styles.dot} />
    <circle cx="117.1" cy="362.1" r="5.5" className={styles.dot} />
    <circle cx="198.5" cy="387.3" r="5.5" className={styles.dot} />
    <circle cx="208.1" cy="363.1" r="5.5" className={styles.dot} />
    <circle cx="74.5" cy="336" r="5.5" className={styles.dot} />
    <circle cx="70.7" cy="226.3" r="5.5" className={styles.dot} />
    <circle cx="70.7" cy="253.5" r="5.5" className={styles.dot} />
  </svg>
);

export default function Logo({ className, url, id }) {
  const [{ scheme }] = useStateValue();
  const [show] = React.useState(true);
  return (
    <div
      id={id}
      title="Human Ventures"
      className={cx(
        { [styles.show]: show, [styles.hide]: !show },
        styles[scheme],
        styles.Logo,
        className
      )}
    >
      {url ? (
        <a href="/" className={cx(styles.LogoLink)}>
          <LogoSVG scheme={scheme} />
        </a>
      ) : (
        <LogoSVG scheme={scheme} />
      )}
    </div>
  );
}
