import { APP_PATHS } from 'common/lib/data';
import ArrowButton from 'components/ArrowButton';
import Card from 'components/Card';
import Logo from 'components/Logo';
import React from 'react';
import styles from './styles.module.scss';

const components = {
  error404: ({ message }) => 'We could not find that code!',
  error401: ({ message }) => "I'm afraid I can't use this code.",
  error403: ({ message }) => 'Hmm, perhaps your invite code has expired?',
  error500: ({ message }) => "It's not you, it's us. Please try again later!",
};

export default function SurveyInvalid({ status, message, data, clearError }) {
  const Component = components[`error${status}`] || components.error500;
  return (
    <Card className={styles.InvalidCard}>
      <Logo className={styles.InvalidLogo} />
      <div className={styles.InvalidContent}>
        <h3>Oops!</h3>
        <Component message={message || 'Oops'} />
        <ArrowButton
          onClick={clearError}
          to={APP_PATHS.root}
          icon="close"
          title="Start Over"
        />
      </div>
    </Card>
  );
}
