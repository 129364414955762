import { camelizeKeys, decamelizeKeys } from 'humps';
import isObject from 'lodash.isobject';
import queryString from 'query-string';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_DELETE = 'DELETE';

const CLIENT_CREDENTIALS = 'same-origin';

export const headers = () => ({
  Accept: 'application/vnd.api+json',
  'Accept-Language': 'en-US',
  'Content-Type': 'application/vnd.api+json',
});

const prepareBody = (body) =>
  body instanceof FormData ? body : JSON.stringify(decamelizeKeys(body));

/**
 * Fetch Wrapper
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 * @param {String} url
 * @param {Object} config
 */
export async function client(
  url,
  { method = METHOD_GET, body, params, additionalHeaders }
) {
  let response;
  try {
    response = await fetch(appendQueryParams(url, params), {
      body: body ? prepareBody(body) : null,
      method: method.toUpperCase(),
      headers: { ...headers(), ...(additionalHeaders || {}) },
      credentials: CLIENT_CREDENTIALS,
    });

    response.parsed = await parseResponse(response);
  } catch (err) {
    err.fetchError = true;
    throw err;
  }

  if (!(response.status >= 200 && response.status < 300)) {
    const error = new Error(response.statusText);
    error.fetchError = true;
    error.response = response;
    throw error;
  }

  return response;
}

/**
 * GET Fetch Wrapper
 * @param {String} url
 * @param {Object} params
 * @returns {Promise}
 */
client.get = async function (url, { params = {}, headers = {} } = {}) {
  return await client(url, {
    method: METHOD_GET,
    params,
    additionalHeaders: headers,
  });
};

/**
 * POST Fetch Wrapper
 * @param {String} url
 * @param {Object} params
 * @returns {Promise}
 */
client.post = async function (url, { body = {}, headers = {} } = {}) {
  return await client(url, {
    method: METHOD_POST,
    body,
    additionalHeaders: headers,
  });
};

/**
 * PUT Fetch Wrapper
 * @param {String} url
 * @param {Object} params
 * @returns {Promise}
 */
client.put = async function (url, { body = {}, headers = {} } = {}) {
  return await client(url, {
    method: METHOD_PUT,
    body,
    additionalHeaders: headers,
  });
};

/**
 * DELETE Fetch Wrapper
 * @param {String} url
 * @param {Object} params
 * @returns {Promise}
 */
client.delete = async function (url, { body = {}, headers = {} } = {}) {
  return await client(url, {
    method: METHOD_DELETE,
    body,
    additionalHeaders: headers,
  });
};

/**
 * @param {Response} response
 * @returns {Object}
 */
const parseResponse = async (response) => {
  try {
    return camelizeKeys(await response.json());
  } catch (err) {
    return;
  }
};

/**
 * @param {String} url
 * @param {Object} params
 * @returns {String} url?params
 */
export const appendQueryParams = (url, params = {}) =>
  queryString.stringifyUrl(
    {
      url,
      query: decamelizeKeys(
        Object.keys(params).reduce((preparedQueryParams, param) => {
          // 1. Default
          // Pass Key/Value back through preparedQueryParams
          let key = param;
          let value = params[param];
          // 2. Object Handling
          // Convert { filter: { name: "johnny" }} => { filter[name]: johnny }
          if (isObject(params[param]) && !Array.isArray(params[param])) {
            // 3a. Format & Apply Object Key / Value
            Object.keys(params[param]).forEach((objectParamKey) => {
              preparedQueryParams[`${param}[${objectParamKey}]`] =
                params[param][objectParamKey];
            });
          } else {
            // 3b. Re-Apply Key / Value
            preparedQueryParams[key] = value;
          }

          return preparedQueryParams;
        }, {})
      ),
    },
    { skipNull: true, arrayFormat: 'bracket' }
  );
