import { client } from './client';
import { APP_PUBLIC_API_HOST, __DEVELOPMENT__ } from './data';

const url = (path) => `${APP_PUBLIC_API_HOST}${path}`;

const apiPublic = {
  postRedeem: ({ code }) =>
    client.post(url('/invites/redeem'), { body: { code } }),
};

export default apiPublic;

/**
 * Expose API functions to window for
 * convenient development & debugging
 */
if (__DEVELOPMENT__) {
  window.FounderPublicAPI = apiPublic;
}
