import { Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { trackEvent } from 'common/lib/analytics';
import { APP_PATHS } from 'common/lib/data';
import { useGenerateFullPath } from 'common/lib/hooks/useGenerateFullPath';
import { useStateValue } from 'common/lib/state';
import { withParagraphs } from 'common/lib/strings';
import Art from 'components/SurveyIntermission/Art';
import React from 'react';
import usePortal from 'react-useportal';
import Modal from '../Modal';
import { emailBody, emailMailto, emailReferralUrl } from './email';
import styles from './styles.module.scss';

const Share = ({ show, onClose }) => {
  const [{ founder, referredFounder }] = useStateValue();
  const { Portal } = usePortal();
  const generateFullPath = useGenerateFullPath();
  const referralUrl = emailReferralUrl({
    path: generateFullPath({
      path: APP_PATHS.root,
    }),
    referredFounder,
  });

  const SendButton = (
    <div className={styles.sendContainer}>
      <Button
        href={emailMailto({ founder, referredFounder, referralUrl })}
        target="_mailto"
        rel="noopener"
        size="large"
        variant="outlined"
        startIcon={<SendIcon />}
        className={styles.sendButton}
        onClick={() => {
          trackEvent(trackEvent.EVENT__CLICK_REFERRAL_MAILTO);
        }}
      >
        Send the code
      </Button>
    </div>
  );

  return (
    <Portal>
      <Modal className={styles.shareModal} show={show} onClose={onClose}>
        <Art className={styles.shareArt} />
        <div className={styles.shareContainer}>
          {SendButton}
          <h2>Email {referredFounder.firstName} their unique founder code!</h2>
          {withParagraphs(emailBody({ founder, referredFounder, referralUrl }))}
          {SendButton}
        </div>
      </Modal>
    </Portal>
  );
};

export default Share;
