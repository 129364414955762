import { SCHEME_LIGHT } from 'common/lib/data';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyResults from 'components/SurveyResults';
import React from 'react';

const ResultsPage = () => {
  useLayoutScheme(SCHEME_LIGHT);

  return (
    <>
      <SEO title="Your Results" />
      <SurveyResults />
    </>
  );
};
export default ResultsPage;
