import cx from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

export default function Card({ children, className, ...props }) {
  return (
    <div {...props} className={cx(styles.Card, className)}>
      {children}
    </div>
  );
}
