import cx from 'classnames';
import { useStateValue } from 'common/lib/state';
import Logo from 'components/Logo';
import Stack from 'components/Stack';
import React from 'react';
import { Chart, Subheading } from '../SurveyResults';
import Details from '../SurveyResults/components/Details';
import styles from '../SurveyResults/styles.module.scss';
import printStyles from './styles.module.scss';

export const PrintInstructions = () => (
  <div
    onClick={() => window.print()}
    className={printStyles.instructions}
    data-instructions="Tap here to print your results or print to PDF"
  />
);

export const PrintHeader = () => (
  <>
    <Logo className={styles.logo} />
    <h2 className={styles.heading}>Human Founder Assessment</h2>
  </>
);

const PrintSection = ({ firstName, children }) => (
  <span className={printStyles.printSection}>
    <PrintHeader />
    <Subheading heading={`${firstName}'s Human Founder Survey Results.`} />
    {children}
  </span>
);

export default function SurveyPrintResults() {
  const [{ founder, survey }] = useStateValue();

  const { firstName } = founder;
  const { evaluation } = survey;

  return (
    (evaluation && (
      <Stack className={cx(styles.stack, printStyles.stack)}>
        <PrintInstructions />
        <PrintSection firstName={firstName}>
          <Chart evaluation={evaluation} />
        </PrintSection>
        {Object.keys(evaluation).map((trait, index) => {
          return (
            <PrintSection key={trait} firstName={firstName}>
              <Details
                detailsTrait={trait}
                printable={true}
                {...evaluation[trait]}
              />
            </PrintSection>
          );
        })}
      </Stack>
    )) ||
    null
    // TODO: Need a failure / wait state for Results if they cannot be delivered
  );
}
