export const emailSubject = ({ referredFounder }) =>
  `${referredFounder.firstName}, try Human's Founder Assessment`;

export const emailBody = ({ founder, referredFounder, referralUrl }) =>
  `
Hi ${referredFounder.firstName},

I just completed the Human Founder Assessment built by Human Ventures and want you to try it too!

Human Ventures has worked closely with dozens of founders to build and scale new ventures, both out of their studio and through their portfolio of investments. Working side by side with each team has given them an inside view into some of the most important traits that make their founders incredible - and most importantly, Human. They’ve codified these insights into a personalized experience for new and seasoned founders and want to share it with the entrepreneurial community.

The proprietary assessment provides an in-depth analysis of a founder’s traits beyond the resume. The goal is to instill a deep understanding and awareness into what makes them great, and what might hold them back.

I get to invite one lucky person to take the assessment and I’ve chosen you!

You can get started with the assessment here ${referralUrl}. I’m excited to see what you think so let me know once you’ve finished taking it.

Best,

${founder.firstName}
`;

export const emailMailto = (params) =>
  `mailto:${params.referredFounder.email}?subject=${emailSubject(
    params
  )}&body=${emailBody(params).replace(/\n/g, '%0D%0A')}`;

export const emailReferralUrl = ({ path = '/', referredFounder }) =>
  `${document.location.protocol}//${document.location.host}${path}?q=${referredFounder.inviteCode}`;
