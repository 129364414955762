import { SCHEME_DARK } from 'common/lib/data';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyRedeem from 'components/SurveyRedeem';
import React from 'react';

const IndexPage = () => {
  useLayoutScheme(SCHEME_DARK);

  return (
    <>
      <SEO />
      <SurveyRedeem />
    </>
  );
};

export default IndexPage;
