import React, { Component } from 'react';
import RadarAxis from '../RadarAxis';
import RadarCircle from '../RadarCircle';
import RadarLevels from '../RadarLevels';

const defaultRadarStyle = {
  numRings: 5,
  axisColor: '#F9F9F9',
  ringColor: '#F9F9F9',
};

function getHovered(event, height, width, padding, radius, voronoiDiagram) {
  const innerHeight = height - padding * 2;
  const innerWidth = width - padding * 2;
  const diameter = radius * 2;

  let { offsetX: clientX, offsetY: clientY } = event;
  clientX -= padding;
  clientY -= padding;
  clientX -= (innerWidth - diameter) / 2;
  clientY -= (innerHeight - diameter) / 2;

  const site = voronoiDiagram.find(clientX, clientY, radius / 2);
  if (!site) {
    return null;
  }

  const { data } = site;
  return data;
}

export default class RadarWrapper extends Component {
  hoverMap = null;

  componentDidMount() {
    if (this.hoverMap) {
      this.hoverMap.addEventListener('mousemove', (event) => {
        const { onHover } = this.props;
        if (!onHover) {
          return;
        }
        const { padding, height, width, radius, voronoiDiagram } = this.props;
        onHover(
          getHovered(event, height, width, padding, radius, voronoiDiagram)
        );
      });
    }
  }

  render() {
    const {
      width,
      height,
      padding,
      radius,
      style,
      highlighted,
      scales,
      variables,
      offsetAngles,
      domainMax,
      highlightedPoint,
      regularPoints,
      backgroundScale,
      colors,
    } = this.props;

    const diameter = radius * 2;
    const { axisColor, ringColor, numRings } = {
      ...defaultRadarStyle,
      ...style,
    };

    const innerHeight = height - padding * 2;
    const innerWidth = width - padding * 2;

    const ticks = backgroundScale.ticks(numRings).slice(1);
    const tickFormat = backgroundScale.tickFormat(numRings);
    const dimensions = {
      innerHeight,
      innerWidth,
    };

    return (
      <svg width={width} height={height}>
        <g
          transform={`translate(${padding}, ${padding})`}
          ref={(c) => {
            this.hoverMap = c;
          }}
        >
          <rect
            width={diameter}
            height={diameter}
            fill={'transparent'}
            transform={`translate(${(innerWidth - diameter) / 2}, ${
              (innerHeight - diameter) / 2
            })`}
          />
          <g transform={`translate(${innerWidth / 2}, ${innerHeight / 2})`}>
            {/* Levels */}
            <RadarLevels
              ticks={ticks}
              scale={backgroundScale}
              axisColor={axisColor}
              ringColor={ringColor}
              format={tickFormat}
            />
            {variables.map(({ key, label }, index) => {
              return (
                <RadarAxis
                  key={key}
                  keyIndex={index}
                  scale={scales[key]}
                  offsetAngle={offsetAngles[key]}
                  label={label}
                  domainMax={domainMax}
                  color={axisColor}
                />
              );
            })}
            {regularPoints.map(({ setKey, points }) => {
              return (
                <RadarCircle
                  dimensions={dimensions}
                  key={setKey}
                  points={points}
                  scales={scales}
                  offsetAngles={offsetAngles}
                  color={colors[setKey]}
                  isSelected={false}
                  selectedVariableKey={null}
                />
              );
            })}
            {highlightedPoint ? (
              <RadarCircle
                key={highlightedPoint.setKey}
                points={highlightedPoint.points}
                scales={scales}
                offsetAngles={offsetAngles}
                color={colors[highlightedPoint.setKey]}
                isSelected={true}
                selectedVariableKey={
                  highlighted ? highlighted.variableKey : null
                }
              />
            ) : null}
          </g>
        </g>
      </svg>
    );
  }
}
