import cx from 'classnames';
import Progress from 'components/Progress';
import React from 'react';
import styles from './styles.module.scss';

const prepareSubtraits = (subtraits) =>
  Object.keys(subtraits).map((type) => {
    return {
      type,
      label: subtraits[type].label,
      percentage: subtraits[type].percentage,
    };
  });

const Outcome = ({ test, type, percentage, label, isPrimary = false }) => (
  <>
    <div className={styles.outcomeTitle}>
      <span>{label}</span>
      <span className={styles.outcomeNumber}>{percentage}</span>
    </div>
    <Progress
      max={100}
      value={percentage}
      ariaLabel={`${percentage} / 100`}
      animate={true}
      className={styles.Progress}
    />
  </>
);

const Card = ({ label, type, percentage, subtraits, onClick, className }) => {
  const outcomes = [
    { type, percentage, label },
    ...prepareSubtraits(subtraits),
  ];

  return (
    <>
      <div
        className={cx(styles.card, styles[type], className)}
        onClick={onClick}
      >
        <div className={styles.content}>
          <Outcome key={outcomes[0].type} {...outcomes[0]} />

          {onClick && (
            <div className={styles.LearnMoreLink}>
              Learn More
              <svg
                width="7"
                height="14"
                viewBox="0 0 9 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L8 9L1 17"
                  stroke="#565656"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Card;
