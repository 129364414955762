import Events from 'common/lib/events';
import { withParagraphs } from 'common/lib/strings';

export const INTERMISSIONS = {
  'intermission--1': {
    heading: `One down, nine to go.`,
    subheading: `You're doing great!`,
  },
  'intermission--2': {
    heading: `Second section complete.`,
    subheading: `Keep going!`,
  },
  'intermission--3': {
    heading: `Part three finished...`,
    subheading: `Section four awaits!`,
  },
  'intermission--4': {
    heading: `40% of the way there!`,
    subheading: `That's almost half way`,
  },
  'intermission--5': {
    heading: `Five out of Ten!`,
    subheading: `The midpoint.`,
  },
  'intermission--6': {
    heading: `Part six is a wrap!`,
    subheading: `You are here.`,
  },
  'intermission--7': {
    heading: `Seventh inning stretch!`,
    subheading: `There's no crying in baseball!`,
  },
  'intermission--8': {
    heading: `Eight sections moving backwards in time.`,
    subheading: `Now on to number nine!`,
  },
  'intermission--9': {
    heading: `One. section. left.`,
    subheading: `Let's do this.`,
  },
  'intermission--10': {
    heading: withParagraphs(`Congratulations!
    You have just completed the Human Ventures Founder Assessment.`),
    subheading: withParagraphs(`Your results will be broken down into five major traits and their aspects.
      It is important to remember that your traits make you wholly you.
      Scoring high or low is not better or worse, each trait has advantages and disadvantages.
      The scores you receive are simply meant to provide a greater sense of self awareness.`),
    callback: () => Events.emit(Events.EVENT.Submit),
  },
};
