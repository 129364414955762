import { APP_PATHS, SCHEME_LIGHT } from 'common/lib/data';
import { useStep } from 'common/lib/surveyHelpers';
import useLayoutScheme from 'components/Layout/useLayoutScheme';
import SEO from 'components/SEO';
import SurveyIntermission from 'components/SurveyIntermission';
import SurveyQuestion from 'components/SurveyQuestion';
import React from 'react';
import { Redirect } from 'react-router-dom';

const COMPONENTS = {
  question: SurveyQuestion,
  intermission: SurveyIntermission,
};

const SurveyPage = ({ match }) => {
  useLayoutScheme(SCHEME_LIGHT);

  const step = useStep({ uid: match.params.step });
  const Component = COMPONENTS[step.page];

  if (!step) {
    return <Redirect to={APP_PATHS.notFound} />;
  }

  return (
    <>
      <SEO title={step.label} />
      <Component {...step} />
    </>
  );
};

export default SurveyPage;
