import airbrake from 'common/lib/airbrake';
import { appendAnalyticsMetadata } from 'common/lib/analytics';
import { APP_CACHE_KEY, INITIAL_STATE } from 'common/lib/data';
import features from 'common/lib/features';
import { appendLogMetadata } from 'common/lib/log';
import persist from 'common/lib/persist';
import reducer from 'common/lib/reducer';
import { StateProvider } from 'common/lib/state';
import App from 'components/App';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import 'config/fonts.css'; // Import Config CSS to ensure build as CSS (not SCSS)
import 'config/theme.css'; // Import Config CSS to ensure build as CSS (not SCSS)
import React from 'react';
import ReactDOM from 'react-dom';
import FullStory from 'react-fullstory';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import styles from './styles.module.scss';

appendLogMetadata({
  version: process.env.REACT_APP__VERSION,
  release: process.env.REACT_APP__COMMIT_REF,
  features: features._all,
});

appendAnalyticsMetadata({
  version: process.env.REACT_APP__VERSION,
  release: process.env.REACT_APP__COMMIT_REF,
  features: features._all,
});

const initialState = {
  ...INITIAL_STATE,
  ...persist.local.read(APP_CACHE_KEY),
};

const FULLSTORY_ORG_ID = process.env.REACT_APP__FULLSTORY_ORG_ID;

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <Router>
      <Layout>
        <SEO />
        <App className={styles.App} />
        {FULLSTORY_ORG_ID && <FullStory org={FULLSTORY_ORG_ID} />}
      </Layout>
    </Router>
  </StateProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Catch errors and send them to Airbrake
window.addEventListener('error', airbrake.notify);
