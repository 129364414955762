const prepareChartData = (evaluation) => ({
  width: 480,
  height: 480,
  domainMax: 100,
  padding: 100,
  style: {
    numRings: 5,
    axisColor: '#F3F2EE',
    ringColor: '#F9F9F9',
  },
  data: {
    variables: Object.keys(evaluation).map((trait) => ({
      key: trait,
      label: `${evaluation[trait].label} ${evaluation[trait].percentage}`,
    })),
    sets: [
      {
        key: 'me',
        label: 'My Scores',
        values: Object.keys(evaluation).reduce((values, trait) => {
          values[trait] = evaluation[trait].percentage;
          return values;
        }, {}),
      },
    ],
  },
});

export default prepareChartData;
