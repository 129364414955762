import ACTIONS from 'common/lib/actions';
import { useStateValue } from 'common/lib/state';
import React from 'react';

const useLayoutScheme = (nextScheme) => {
  const [{ scheme }, dispatch] = useStateValue(); // eslint-disable-line no-empty-pattern

  React.useEffect(() => {
    nextScheme !== scheme &&
      dispatch({
        type: ACTIONS.SET_COLOR_SCHEME,
        scheme: nextScheme,
      });
  }, [dispatch, nextScheme, scheme]);
};

export default useLayoutScheme;
