import { scaleLinear } from 'd3-scale';
import _ from 'lodash';

export function flatMapDeepArray(collection, fn) {
  return _.flatMapDeep(collection, fn);
}
export function forEachArray(collection, fn) {
  _.forEach(collection, fn);
}

export function radiusScales(variables, domainMax, radius) {
  const res = {};
  _.forEach(variables, ({ key }) => {
    const scale = scaleLinear().domain([0, domainMax]).range([0, radius]);
    res[key] = scale;
  });
  return res;
}

export function radarPoints(data, scales, offsetAngles) {
  const allVariableKeys = data.variables.map((variable) => variable.key);

  return data.sets.map(({ key, values }) => {
    const points = [];
    _.forEach(values, (value, variableKey) => {
      const scale = scales[variableKey];
      const offsetAngle = offsetAngles[variableKey];
      if (scale === undefined || offsetAngle === undefined) {
        return;
      }

      const x = scale(value) * Math.cos(offsetAngle - Math.PI / 2);
      const y = scale(value) * Math.sin(offsetAngle - Math.PI / 2);

      const point = {
        x,
        y,
        value,
        setKey: key,
        variableKey,
        key: `${key}--${variableKey}`,
      };
      points.push(point);
    });

    const sortedPoints = _.sortBy(points, (point) => {
      const pointVariableKey = point.variableKey;
      return _.indexOf(allVariableKeys, pointVariableKey);
    });

    return { setKey: key, points: sortedPoints };
  });
}
